define("spotassist/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3PzlwO0K",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"meta\"],[11,\"name\",\"prerenderReady0\"],[12,\"content\",[23,[\"model\",\"prerenderReady1\"]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"name\",\"prerenderReady1\"],[12,\"content\",[23,[\"model\",\"prerenderReady1\"]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"name\",\"prerenderReady2\"],[12,\"content\",[23,[\"model\",\"prerenderReady2\"]]],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"title\"]]],null,{\"statements\":[[0,\"  \"],[7,\"title\"],[9],[1,[23,[\"model\",\"title\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"title\"],[9],[0,\"Spot Assist World Dropzones Atlas\"],[10],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"link\"],[11,\"rel\",\"icon\"],[11,\"href\",\"//www.spotassist.com/wp-content/uploads/2014/01/spotassist_favicon.png\"],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"name\",\"fragment\"],[11,\"content\",\"!\"],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"fb:app_id\"],[12,\"content\",[23,[\"model\",\"fb_app_id\"]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:type\"],[11,\"content\",\"website\"],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:site_name\"],[11,\"content\",\"Spot Assist World Dropzones Atlas\"],[9],[10],[0,\"\\n\\n\"],[1,[27,\"head-tags\",null,[[\"headTags\"],[[23,[\"model\",\"headTags\"]]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/head.hbs"
    }
  });
});