define("spotassist/templates/dialogs/purchase-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eF3zmtsr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"panel panel-default\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel-heading\"],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"panel-title\"],[9],[0,\"Oops, something is wrong with your purchase \"],[1,[23,[\"contextObject\",\"currentDropzone\",\"dz_name\"]],false],[0,\" Big Picture\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n        We are sorry. We cannot complete your order. We will investigate it immediately and will contact you to complete what you have started.\\n\\n        \"],[7,\"hr\"],[11,\"class\",\"half-rule\"],[9],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/dialogs/purchase-error.hbs"
    }
  });
});