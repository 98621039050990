define("spotassist/components/dropzone-item-form-bigpicture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      createOnEnter: function createOnEnter(select, e) {
        //select.createOnEnter(select, e);

        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          //let selected = this.get('selected');
          var selected = select.selected;
          if (!selected || !selected.includes(select.searchText)) {
            var options = select.options;
            options.pushObject(select.searchText);
            select.actions.choose(select.searchText);
          }
        }
      },
      resetLandingCorridor: function resetLandingCorridor() {
        this.get('item').set('dz_landing_corridor', -1);
      },
      saveDropzoneAction: function saveDropzoneAction(dropzone) {
        //console.log('saveDropzoneAction');
        this.get('saveDropzoneAction')(dropzone);
      },
      cancelEditDropzoneAction: function cancelEditDropzoneAction(dropzone) {
        this.get('cancelEditDropzoneAction')(dropzone);
      },
      deleteDropzoneAction: function deleteDropzoneAction(dropzone) {
        //console.log('deleteDropzoneAction');
        this.get('deleteDropzoneAction')(dropzone);
      },
      geocodeDropzoneAction: function geocodeDropzoneAction(dropzone) {
        this.get('geocodeDropzoneAction')(dropzone);
      },
      setValue: function setValue(name, value) {
        //this.sendAction('setDzName', name);
        this.get('item').set(name, value);
      },
      setValueFromParam: function setValueFromParam(name, param, value) {
        this.get('item').set(name, value[param]);
      }
    },
    init: function init() {
      this._super();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('item').set('selected', true);
      this.get('item').set('editMode', true);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('item').set('selected', false);
      this.get('item').set('editMode', false);
      this._super.apply(this, arguments);
    }
  });
});