define("spotassist/templates/typeahead-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "H3+x0A0d",
    "block": "{\"symbols\":[],\"statements\":[[0,\"No dropzones found. \\n\"],[7,\"br\"],[9],[10],[0,\"\\nTo create new dropzone please type address\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/typeahead-not-found.hbs"
    }
  });
});