define("spotassist/components/notify-updater-button", ["exports", "spotassist/utils/global-error-handler", "spotassist/config/environment"], function (_exports, _globalErrorHandler, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      notifyUpdaterAction: function notifyUpdaterAction() {
        var dropzone = this.get('dropzone');
        dropzone.set('notify-progress', true);
        dropzone.set('notify-error', false);
        var notifyUpdaterUrl = _environment.default.adapter_host + "/crowd-user-dropzones/" + dropzone.get('id') + "/request/?notify-updater=true";
        Ember.$.ajax(notifyUpdaterUrl).then(function (dropzones) {
          dropzone.set('digested', dropzones[0].digested);
          dropzone.set('notify-progress', false);
          dropzone.set('notify-error', false);
        }, function (error) {
          var message = "Error requesting info: dropzone";
          console.error("error:" + message + error);
          (0, _globalErrorHandler.default)(error, message);
          dropzone.set('notify-progress', false);
          dropzone.set('notify-error', true);
        });
      }
    },
    progress: Ember.computed('dropzone.notify-progress', function () {
      return this.get('dropzone.notify-progress');
    }),
    error: Ember.computed('dropzone.notify-error', function () {
      return this.get('dropzone.notify-error');
    }),
    success: Ember.computed('dropzone.{notify-progress,notify-error}', function () {
      if (this.get('dropzone.notify-progress') == null) {
        return false;
      } else {
        return !this.get('dropzone.notify-progress') && !this.get('dropzone.notify-error');
      }
    })
  });
});