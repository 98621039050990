define("spotassist/templates/dropzones/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BzbenQE+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"spotassist-box\"],[9],[0,\"\\n\\n     \"],[7,\"div\"],[11,\"class\",\"panel panel-default\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"panel-body1\"],[9],[0,\"\\n               \"],[7,\"div\"],[12,\"class\",[27,\"if\",[[23,[\"selectedDropzone\"]],\"\",\"hidden\"],null]],[9],[0,\"\\n                    \"],[1,[27,\"dropzone-panel\",null,[[\"session\",\"localSettings\",\"dropzone\",\"dropzoneEditMode\",\"tabsLayout\",\"flowLayut\",\"infoTabSelected\",\"weatherTabSelected\",\"mapTabSelected\",\"selectDzTabAction\",\"zoomToDropzoneAction\",\"editDropzoneAction\",\"cancelEditDropzoneAction\",\"saveDropzoneAction\",\"deleteDropzoneAction\",\"geocodeDropzoneAction\",\"updateDropzoneProgressListener\",\"weatherSource\"],[[23,[\"session\"]],[23,[\"localSettings\"]],[23,[\"selectedDropzone\"]],[23,[\"dropzoneEditMode\"]],[23,[\"tabsLayout\"]],[23,[\"flowLayut\"]],[23,[\"infoTabSelected\"]],[23,[\"weatherTabSelected\"]],[23,[\"mapTabSelected\"]],[27,\"action\",[[22,0,[]],\"selectDzTabAction\"],null],[27,\"action\",[[22,0,[]],\"zoomToDropzoneAction\"],null],[27,\"action\",[[22,0,[]],\"editDropzoneAction\"],null],[27,\"action\",[[22,0,[]],\"cancelEditDropzoneAction\"],null],[27,\"action\",[[22,0,[]],\"saveDropzoneAction\"],null],[27,\"action\",[[22,0,[]],\"deleteDropzoneAction\"],null],[27,\"action\",[[22,0,[]],\"geocodeDropzoneAction\"],null],[23,[\"updateDropzoneProgressListener\"]],[23,[\"weatherSource\"]]]]],false],[0,\"\\n               \"],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[1,[27,\"dropzone-panel-header\",null,[[\"callToAction\",\"dropup\"],[\"openFullMap\",true]]],false],[0,\"\\n     \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/dropzones/view.hbs"
    }
  });
});