define("spotassist/models/bigpicture-purchase", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var _default = _exports.default = Model.extend({
    product: attr(),
    amount: attr(),
    email: attr(),
    stripe_token: attr(),
    dz_id: attr()
  });
});