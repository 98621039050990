define("spotassist/objects/gfs-point-object", ["exports", "spotassist/objects/gfs-location-object", "spotassist/objects/gfs-dated-weather-object"], function (_exports, _gfsLocationObject, _gfsDatedWeatherObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import ENV from "spotassist/config/environment";

  /* global com */

  var GfsPoint = Ember.Object.extend({
    location: null,
    results: null,
    init: function init() {
      this._super();
      this.set('__interfaces', "com.livewings.spotassist.library.gfs.IGfsPoint");
    },
    getLocation: function getLocation() {
      return _gfsLocationObject.default.create(this.get('location'));
    },
    getResults: function getResults() {
      //return this.get('results');
      var resultsArray = this.get('results').map(function (r) {
        return _gfsDatedWeatherObject.default.create(r);
      });
      return resultsArray;
    }
  });
  var _default = _exports.default = GfsPoint;
});