define("spotassist/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "spotassist/utils/load-current-user"], function (_exports, _applicationRouteMixin, _loadCurrentUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    metrics: Ember.inject.service(),
    raven: Ember.inject.service(),
    actions: {
      /*
      sessionAuthenticationSucceeded: function() {
        var previousRouteName = this.controllerFor('application').get('previousRouteName');
        this.transitionTo(previousRouteName);
      },
      */
    },
    beforeModel: function beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        //this.transitionTo('login');
      } else {
        (0, _loadCurrentUser.default)(this.get("session"), this.get("store"));
      }
    },
    /*
      model() {
        if(this.get('session.isAuthenticated')) {
          return Ember.RSVP.hash({
            collections: this.store.findAll('collection'),
          });
        } else {
          return null;
        }
      },
    */
    sessionInvalidated: function sessionInvalidated() {
      Ember.$.ajaxSetup({
        headers: {
          'Authorization': 'None'
        }
      });
      this.set('session.user', null);
      this.set('session.userAcl', null);
      Ember.get(this, 'metrics').trackEvent({
        event: 'Logout'
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      //this._super(...arguments);

      Ember.get(this, 'metrics').trackEvent({
        event: 'Login'
      });
      (0, _loadCurrentUser.default)(this.get("session"), this.get("store"));
      if (this.get('session.signupSuccess')) {
        this.transitionTo('auth.registered');
      } else if (this.controllerFor('application').get('currentRouteName') === 'auth.signup' || this.controllerFor('application').get('currentRouteName') === 'auth.login') {
        /*
        if(this.get('session.lastUnauthenticatedRoute')) {
          var lastUnauthenticatedRoute = this.get('session.lastUnauthenticatedRoute');
          var lastUnauthenticatedRouteParams = this.get('session.lastUnauthenticatedRouteParams');
            var values= [];
          for (var key in lastUnauthenticatedRouteParams) {
            if( lastUnauthenticatedRouteParams.hasOwnProperty(key) ) {
              var value = lastUnauthenticatedRouteParams[key]
               values.push(value);
            }
          }
            this.get('router').transitionTo(lastUnauthenticatedRoute, values);
          //this.get('router').transitionTo('dropzones.bigpicture', ["13"]);
         }
        */
        this.transitionTo('index');
      }
    },
    userIdentified: Ember.observer('session.user', function () {
      var user = this.get('session.user');
      if (user) {
        this.get('raven').callRaven('setUserContext', {
          name: user.fullname,
          email: user.username,
          id: user.username
        });
        Ember.get(this, 'metrics').identify({
          name: user.fullname,
          email: user.username,
          id: user.username
        });
      } else {
        this.get('raven').callRaven('setUserContext', null);
        Ember.get(this, 'metrics').identify();
      }
    })
  });
});