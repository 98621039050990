define("spotassist/objects/canopy-object", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Canopy = Ember.Object.extend({
    name: null,
    glideRatio: null,
    speed: null,
    init: function init() {
      this._super();
      this.set('__interfaces', "com.livewings.spotassist.library.json.ICanopy");
    },
    //public int getCanopyId();
    getName: function getName() {
      return this.get('name');
    },
    getGlideRatio: function getGlideRatio() {
      return this.get('glideRatio');
    },
    getSpeedKts: function getSpeedKts() {
      return this.get('speed');
    },
    getSpeedMs: function getSpeedMs() {
      return this.getSpeedKts() * _environment.default.CONSTANTS.KNOTS_TO_METERSPERSEC;
    }
  });
  var _default = _exports.default = Canopy;
});