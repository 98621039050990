define("spotassist/serializers/acl", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'user',
    attrs: {
      permissions: {
        embedded: 'always'
      }
    }
  });
  /*
  export default DS.JSONSerializer.extend({
    primaryKey: 'user'
  });
  */
});