define("spotassist/objects/java-date-object", ["exports", "spotassist/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JavaDate = Ember.Object.extend({
    time: null,
    init: function init() {
      this._super();

      //this.set('__interfaces', "com.livewings.spotassist.library.json.ICanopy");
    },
    //public int getCanopyId();
    getTime: function getTime() {
      var utcMoment = (0, _moment.default)(this.get('time'), 'YYYY-MM-DDTHH:mm:ss.SSSZ').utc();
      var time = utcMoment.toDate().getTime();
      return time;
    }
  });
  var _default = _exports.default = JavaDate;
});