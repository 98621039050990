define("spotassist/templates/dropzones/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "00DLX6Cb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"btn btn-default\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"glyphicon glyphicon-align-justify\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"openNavAction\"]],[10],[0,\"\\n\\n\"],[7,\"h1\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"],[7,\"p\"],[9],[1,[23,[\"model\",\"errors\",\"0\",\"status\"]],false],[0,\": \"],[1,[23,[\"model\",\"errors\",\"0\",\"detail\"]],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/dropzones/error.hbs"
    }
  });
});