define("spotassist/templates/auth/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "glAY+t7T",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container container-signup\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-6 col-md-offset-3\"],[9],[0,\"\\n            \"],[1,[27,\"login-form\",null,[[\"fullPage\",\"formType\"],[true,\"signup\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/auth/signup.hbs"
    }
  });
});