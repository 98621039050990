define("spotassist/components/digester-siblings-button", ["exports", "moment", "spotassist/utils/global-error-handler"], function (_exports, _moment, _globalErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      markDigestedAction: function markDigestedAction() {
        var dropzone = this.get('dropzone');
        var dz_id = dropzone.get('dz_id');
        if (dz_id) {
          this.get('store').query('crowd-user-dropzone', {
            dz_id: dz_id
          }).then(function (userDropzones) {
            // Do something with `peters`
            userDropzones.forEach(function (userDropzone) {
              userDropzone.set('digested', (0, _moment.default)().utc().format());
              userDropzone.set('digest-siblings-progress', true);
              userDropzone.set('digest-siblings-error', false);
              userDropzone.save().then(function () {
                userDropzone.set('digest-siblings-progress', false);
                userDropzone.set('digest-siblings-error', false);
              }, function (error) {
                var message = "Error saving store: dropzone";
                console.error(message + error);
                (0, _globalErrorHandler.default)(error, message);
                userDropzone.set('digest-siblings-progress', false);
                userDropzone.set('digest-siblings-error', true);
              });
            });
          });
        } else {
          dropzone.set('digest-siblings-progress', false);
          dropzone.set('digest-siblings-error', false);
        }
      }
    },
    progress: Ember.computed('dropzone.digest-siblings-progress', function () {
      return this.get('dropzone.digest-siblings-progress');
    }),
    error: Ember.computed('dropzone.digest-siblings-error', function () {
      return this.get('dropzone.digest-siblings-error');
    }),
    success: Ember.computed('dropzone.{digest-siblings-progress,digest-siblings-error}', function () {
      if (this.get('dropzone.digest-siblings-progress') == null) {
        return false;
      } else {
        return !this.get('dropzone.digest-siblings-progress') && !this.get('dropzone.digest-siblings-error');
      }
    })
  });
});