define("spotassist/models/dropzone", ["exports", "ember-data", "spotassist/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  /* global L */
  var _default = _exports.default = Model.extend({
    dz_name: attr('string'),
    dz_address: attr('string'),
    lat: attr('string'),
    lon: attr('string'),
    dz_phone: attr('string'),
    dz_email: attr('string'),
    dz_url: attr('string'),
    facebook_url: attr('string'),
    dz_elevation_m: attr(),
    dz_landing_corridor: attr(),
    dz_jumprun: attr(),
    dz_pattern_strategy: attr(),
    dz_downwind_ft: attr(),
    dz_base_ft: attr(),
    dz_final_ft: attr(),
    dz_ident: attr('string'),
    updated: attr('string'),
    active: attr(),
    dz_outs: attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    dz_pattern_bounds: attr('string'),
    dz_wu_station_id: attr('string'),
    dz_wu_api_key: attr('string'),
    session: Ember.inject.service('session'),
    manual: false,
    dz_outs_shapes: Ember.computed('dz_outs', function () {
      console.info('retrieving dz shapes');
      var shapes = [];
      this.get('dz_outs').forEach(function (dz_out) {
        var shape = JSON.parse(dz_out.shape_geojson);
        shapes.addObject(shape);
      });
      return shapes;
    }),
    dzElevationFixer: Ember.observer('dz_elevation_m', function () {
      if (this.get('dz_elevation_m') === '') {
        this.set('dz_elevation_m', null);
      }
    }),
    patternStrategies: Ember.computed(function () {
      return _environment.default.PATTERN_STRATEGIES;
    }),
    patternStrategy: Ember.computed('dz_pattern_strategy', function () {
      var patternStrategy = _environment.default.PATTERN_STRATEGIES.findBy('value', this.get('dz_pattern_strategy'));
      return patternStrategy;
    }),
    location: Ember.computed('lat', 'lon', function () {
      return [this.get('lat'), this.get('lon')];
    }),
    displayName: Ember.computed('dz_name', function () {
      return this.get('dz_name');
    }),
    computedId: Ember.computed('id', function () {
      return this.get('id');
    }),
    currentUserCanEdit: Ember.computed('session.userAcl', function () {
      if (!this.get("session.userAcl") || !this.get("session.userAcl").hasDropzoneEditAccess(this.get("id"))) {
        return false;
      } else {
        return true;
      }
    }),
    popupOpen: Ember.computed('selected', 'editMode', function () {
      //console.log('icon change for '+this.get('dz_name')+' selected:'+this.get('selected')+" controller:"+controller);

      return this.get('selected') && this.get('editMode');
    }),
    draggable: Ember.computed('selected', 'editMode', function () {
      var draggable = this.get('selected') === true && this.get('editMode') === true;
      //var draggable = false;
      //console.log('draggable change for '+this.get('dz_name')+' draggable:'+draggable);

      return draggable;
    }),
    icon: Ember.computed('selected', 'icon_type', function () {
      //console.log('icon change for '+this.get('dz_name')+' selected:'+this.get('selected'));

      if (this.get('icon_type') === 'unregistered') {
        return new L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/spotassist_web_logo25.png'
        });
      } else {
        if (this.get('selected')) {
          return new L.icon({
            iconUrl: _environment.default.rootURL + 'assets/images/dropzone_selected.png',
            //iconSize:     [32, 37],
            iconAnchor: [16, 37],
            popupAnchor: [0, -37]
          });
        } else {
          return new L.icon({
            iconUrl: _environment.default.rootURL + 'assets/images/dropzone.png',
            iconAnchor: [16, 37],
            popupAnchor: [0, -37]
          });
        }
      }
    }),
    landingCorridorSet: Ember.computed('dz_landing_corridor', function () {
      return this.get('dz_landing_corridor') >= 0;
    }),
    jumprunSet: Ember.computed('dz_jumprun', function () {
      return this.get('dz_jumprun') >= 0;
    }),
    landingCorridorIcon: Ember.computed(function () {
      var rotation = this.get('rotationRad');
      var xShift = -143 * Math.sin(rotation);
      var yShift = 143 * Math.cos(rotation);

      //var xShift = 50;
      //var yShift = -50;

      return new L.icon({
        iconUrl: _environment.default.rootURL + 'assets/images/landing_corridor_black.png',
        iconAnchor: [36, 143],
        //popupAnchor: [ - (143*Math.cos(this.get('rotation'))), - (143*Math.sin(this.get('rotation')))],
        popupAnchor: [xShift, yShift],
        className: 'corridor-opacity'
      });
    }),
    jumprunIcon: Ember.computed(function () {
      var rotation = this.get('jumprunRotationRad');
      //var xShift = -143*Math.sin(rotation);
      //var yShift = 143*Math.cos(rotation);
      var xShift = -1 * Math.sin(rotation);
      var yShift = 1 * Math.cos(rotation);

      //var xShift = 50;
      //var yShift = -50;

      return new L.icon({
        iconUrl: _environment.default.rootURL + 'assets/images/jumprun_black.png',
        iconAnchor: [36, 143],
        //popupAnchor: [ - (143*Math.cos(this.get('rotation'))), - (143*Math.sin(this.get('rotation')))],
        popupAnchor: [xShift, yShift],
        className: 'corridor-opacity'
      });
    }),
    rotationRad: Ember.computed('rotation', function () {
      return this.get('rotation') * (Math.PI / 180);
    }),
    rotation: Ember.computed('dz_landing_corridor', function () {
      return this.get('dz_landing_corridor');
    }),
    jumprunRotationRad: Ember.computed('jumprunRotation', function () {
      return this.get('jumprunRotation') * (Math.PI / 180);
    }),
    jumprunRotation: Ember.computed('dz_jumprun', function () {
      return this.get('dz_jumprun');
    }),
    collectValues: function collectValues(value_names, value_name) {
      var names = this.get(value_names);
      if (!names) {
        names = [];
        this.set(value_names, names);
      }
      if (names.indexOf(this.get(value_name)) < 0) {
        names.push(this.get(value_name));
      }
      if (this.get('crowdDropzones')) {
        this.get('crowdDropzones').forEach(function (crowdDropzone) {
          if (names.indexOf(crowdDropzone.get(value_name)) < 0) {
            names.push(crowdDropzone.get(value_name));
          }
        });
      }
      if (this.get('aroundDropzones')) {
        this.get('aroundDropzones').forEach(function (aroundDropzone) {
          if (names.indexOf(aroundDropzone.get(value_name)) < 0) {
            names.push(aroundDropzone.get(value_name));
          }
        });
      }
      return names;
    },
    canBeSaved: Ember.computed('hasDirtyAttributes', 'dz_name', function () {
      return this.get('hasDirtyAttributes') && this.get('dz_name');
    }),
    crowd_dz_name: Ember.computed('dz_name', 'crowdDropzones', function () {
      return this.collectValues('dz_names', 'dz_name');
    }),
    crowd_dz_elevation_ms: Ember.computed('dz_elevation_m', 'crowdDropzones', function () {
      return this.collectValues('dz_elevation_ms', 'dz_elevation_m');
    }),
    crowd_dz_idents: Ember.computed('dz_ident', 'crowdDropzones', function () {
      return this.collectValues('dz_idents', 'dz_ident');
    }),
    crowd_dz_addresses: Ember.computed('dz_address', 'crowdDropzones', function () {
      return this.collectValues('dz_addresses', 'dz_address');
    }),
    crowd_dz_emails: Ember.computed('dz_email', 'crowdDropzones', function () {
      return this.collectValues('dz_emails', 'dz_email');
    }),
    crowd_dz_phones: Ember.computed('dz_phone', 'crowdDropzones', function () {
      return this.collectValues('dz_phones', 'dz_phone');
    }),
    crowd_dz_urls: Ember.computed('dz_url', 'crowdDropzones', function () {
      return this.collectValues('dz_urls', 'dz_url');
    }),
    crowd_facebook_urls: Ember.computed('facebook_url', 'crowdDropzones', function () {
      return this.collectValues('facebook_urls', 'facebook_url');
    })
  });
});