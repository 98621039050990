define("spotassist/router", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });
  Router.map(function () {
    this.route('raob-stations', function () {
      this.route('new');
    });
    this.route('dropzones', function () {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: ':dropzone_id'
      });
      this.route('crowd');
      this.route('bigpicture', {
        path: ':dropzone_id/bigpicture'
      });
    });
    this.route('wizard');
    this.route('usage');
    this.route('auth', function () {
      this.route('login');
      this.route('signup');
      //this.route('activate', { path: '/activate/:uid/:token' });
      this.route('registered');
      this.route('password-reset');
      this.route('password-reset-confirm', {
        path: 'password-reset-confirm/:uid/:token'
      });
      this.route('password-reset-done');
      this.route('password-reset-success');
    });
    this.route('profile');
    this.route('what-is-bigpicture');
    this.route('tutorials');
  });
  var _default = _exports.default = Router;
});