define("spotassist/utils/calc-utils", ["exports", "spotassist/objects/canopy-object", "spotassist/objects/dropzone-object"], function (_exports, _canopyObject, _dropzoneObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';

  /* global com */

  var canopy = _canopyObject.default.create({
    name: "Student",
    glideRatio: 2,
    speed: 20
  });
  var patternPoints = [new com.livewings.spotassist.library.math.PatternPoint(0, "Base To Final", "Final", "point3"), new com.livewings.spotassist.library.math.PatternPoint(90, "Downwind To Base", "Base", "point2"), new com.livewings.spotassist.library.math.PatternPoint(180, "Start Downwind", "Downwind", "point1")];
  var _default = _exports.default = {
    calcCanopyRange: function calcCanopyRange(dropzoneObject, altitudeFromFt, altitudeToFt, windaloft, metar) {
      var drawingWarnings = null;

      //var canopyBackwardFlying = false;

      var dropzone = _dropzoneObject.default.create({
        dropzone: dropzoneObject
      });
      var flightUnits = com.livewings.spotassist.library.math.SkydiveMath.getAirColumnWinds(altitudeFromFt, altitudeToFt, windaloft, metar, drawingWarnings);
      //var canopyBackwardFlying = com.livewings.spotassist.library.math.SkydiveMath.isCanopyBackwardFlying(canopy, flightUnits);
      //canopyBackwardFlying = canopyBackwardFlying || canopyBackwardFlying;

      var polygonMapPoints = com.livewings.spotassist.library.math.SkydiveMath.calcRangePolygonGeo(dropzone, canopy, flightUnits, altitudeFromFt, altitudeToFt, drawingWarnings);
      return polygonMapPoints;
    },
    calcCanopyRangeWinds: function calcCanopyRangeWinds(dropzoneObject, altitudeTop, altitudeBottom, windaloft, metar) {
      var drawingWarnings = null;
      var dropzone = _dropzoneObject.default.create({
        dropzone: dropzoneObject
      });
      var flightUnits = com.livewings.spotassist.library.math.SkydiveMath.getAirColumnWinds(altitudeTop, altitudeBottom, windaloft, metar, drawingWarnings);
      var windArrows = com.livewings.spotassist.library.math.SkydiveMath.calcWindsArrowsGeo(dropzone, canopy, flightUnits, altitudeTop, altitudeBottom, true);
      return windArrows;
    },
    calcPattern: function calcPattern(dropzoneObject, metar) {
      var drawingWarnings = null;
      var dropzone = _dropzoneObject.default.create({
        dropzone: dropzoneObject
      });
      var pattern = com.livewings.spotassist.library.json.Pattern.LEFT;
      var flightUnits = com.livewings.spotassist.library.math.SkydiveMath.getAirColumnWinds(0, 0, null, metar, drawingWarnings);
      var landingDirection = -1;
      if (dropzoneObject.get('dz_landing_corridor') >= 0) {
        var metarWindDirection = metar.wind_dir_degrees;
        landingDirection = com.livewings.spotassist.library.math.SkydiveMath.getUpCorridorLandingDirection(metarWindDirection, dropzoneObject.get('dz_landing_corridor'));
      }
      if (dropzoneObject.get('dz_pattern_strategy')) {
        pattern = com.livewings.spotassist.library.math.SkydiveMath.calcPatternForLandingDirectionAndStrategyString(landingDirection, dropzoneObject.get('dz_pattern_strategy'));
      }

      /*
      if(dropzone.getDz_pattern_strategy()!=null && dropzone.getDz_pattern_strategy()!= PatternStrategy.PATTERN_NO_STRATEGY) {
        //if(dropzone.getDz_landing_corridor()>=0 && dropzone.getDz_pattern_strategy()!=null) {
        Pattern pattern = SkydiveMath.calcPatternForLandingDirectionAndStrategy(landingDirection, dropzone.getDz_pattern_strategy());
        if(pattern!=this.settingsProvider.getPattern()) {
          this.settingsProvider.setPattern(pattern);
        }
      }
      */

      var patternData = com.livewings.spotassist.library.math.SkydiveMath.calcPatternGeo(dropzone, canopy, flightUnits, patternPoints, pattern, landingDirection, true);
      if (patternData.isCanopyPatternBackwardFlying()) {
        return null;
      }
      return patternData;
    }
  };
});