define("spotassist/templates/typeahead-suggestion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0EsbIlCP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"from_address\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"span\"],[11,\"class\",\"badge\"],[9],[0,\"Create new\"],[10],[0,\" at: \"],[1,[23,[\"model\",\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\tDropzone: \"],[1,[23,[\"model\",\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/typeahead-suggestion.hbs"
    }
  });
});