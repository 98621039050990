define("spotassist/components/top-navbar", ["exports", "spotassist/config/environment", "spotassist/utils/permissions-utils"], function (_exports, _environment, _permissionsUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    all_permissions: Ember.computed.alias('session.userAcl.all_permissions'),
    environmentDev: Ember.computed(function () {
      return _environment.default.environment === 'development';
    }),
    actions: {
      showBigPicture: function showBigPicture(dropzone) {
        this.get('router').transitionTo('dropzones.bigpicture', [dropzone.get('id')]);
      },
      toggleAllRaobs: function toggleAllRaobs() {
        this.get('user-settings').set('allRaobsOn', !this.get('user-settings').get('allRaobsOn'));
      },
      toggleAllOpenweather: function toggleAllOpenweather() {
        this.get('user-settings').set('allOpenweatherOn', !this.get('user-settings').get('allOpenweatherOn'));
      }
    },
    administeredDropzones: Ember.computed('all_permissions', function () {
      return _permissionsUtils.default.findAdministeredDropzones(this.get('session'), this.get('store'), true);
    })
  });
});