define("spotassist/utils/request-gfs-weather", ["exports", "spotassist/config/environment", "spotassist/objects/gfs-point-object", "spotassist/objects/gfs-helper-object"], function (_exports, _environment, _gfsPointObject, _gfsHelperObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = requestGfsWeather;
  function requestGfsWeather(dropzone) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var weatherUrl = _environment.default.gfs_adapter_host + "/api/weather?lat=" + dropzone.get('lat') + "&lon=" + dropzone.get('lon') + "&" + "&radius_m=20000";
      //console.log("will request dz weather:"+weatherUrl);

      Ember.$.ajax(weatherUrl).then(function (gfsPoints) {
        var gfsPointObjects = [];
        for (var i = 0; i < gfsPoints.length; i++) {
          var gfsPoint = gfsPoints[i];
          var gfsPointObject = _gfsPointObject.default.create(gfsPoint);
          gfsPointObjects.push(gfsPointObject);
        }
        var gfsHelper = _gfsHelperObject.default.create();
        var gfsConverter = new com.livewings.spotassist.library.gfs.GfsConverter(gfsHelper);
        var gfsStations = gfsConverter.convertGfsToStations(gfsPointObjects);
        for (var i = 0; i < gfsStations.length; i++) {
          gfsStations[i].distance = gfsPointObjects[i].distance_m * 0.000621371192;
        }

        /*
        var metars = [];
        for(var i=0; i<gfsPointObjects.length; i++) {
          var gfsPointObject = gfsPointObjects[i];
           var metar = gfsUtils.extractMetar(gfsPointObject);
          metars.push(metar);
        }
        */

        resolve(gfsStations);
        //successCallback(windaloftStations, rawinsondeStations, metarStations);
      }, function (error) {
        var message = "Error loading url: " + weatherUrl;
        console.error(message + error);
        resolve(null);
      });
    });
  }
});