define("spotassist/models/permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Ember from 'ember';
  //import moment from 'moment';
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var _default = _exports.default = Model.extend({
    user_email: attr(),
    //object_id: attr("number"),
    object_id: attr("string"),
    permission_name: attr(),
    permission_valid_to: attr(),
    type: attr()

    //user_abilities: DS.hasMany('user-ability')
  });
});