define("spotassist/models/anonymous-dropzone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _exports.default = _emberData.default.Model.extend({
    user_uuid: attr('string'),
    dz_id: attr('string'),
    dz_name: attr('string'),
    dz_address: attr('string'),
    lat: attr('string'),
    lon: attr('string'),
    dz_phone: attr('string'),
    dz_email: attr('string'),
    dz_url: attr('string'),
    facebook_url: attr('string'),
    dz_elevation_m: attr('string'),
    dz_ident: attr('string'),
    active: attr()
  });
});