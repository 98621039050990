define("spotassist/components/dropzones-acl", ["exports", "spotassist/utils/dropzone-search", "spotassist/utils/permissions-utils", "spotassist/utils/request-wu-weather"], function (_exports, _dropzoneSearch, _permissionsUtils, _requestWuWeather) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    dialog: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    all_permissions: Ember.computed.alias('session.userAcl.all_permissions'),
    error: null,
    email: "",
    expires: null,
    actions: {
      searchAdministeredDropzonesAsync: function searchAdministeredDropzonesAsync(query) {
        var store = this.get('store');
        var cacheContainer = this;
        return (0, _dropzoneSearch.default)(query, store, cacheContainer);
      },
      selectAdministeredDropzone: function selectAdministeredDropzone(dropzone) {
        console.info('selectAdministeredDropzone: ');
        this.set('selectedDropzone', dropzone);
      },
      showBigPicture: function showBigPicture(dropzone) {
        this.get("router").transitionTo('dropzones.bigpicture', [dropzone.get('id')]);
      },
      showUserForm: function showUserForm(userType) {
        var _this = this;
        this.set('userType', userType);
        var promise = this.get("dialog").blank("dialogs/new-user", this, {
          acceptHandler: "saveUser",
          declineHandler: "cancel"
        });

        // Always when dialog closes
        promise.finally(function () {
          _this.set("error", null);
        });
      },
      saveUser: function saveUser(presenter) {
        var email = this.get("email");
        var expires = this.get("expires");
        if (Ember.isEmpty(email)) {
          // Write down the error to controller
          this.set("error", "Form is invalid");
        } else {
          // Saving user and closing dialog window
          //user.save().then(presenter.accept);

          var userType = this.get('userType');
          var permission = null;
          if (userType === 'owner') {
            permission = 'administer_dropzone';
          }
          if (userType === 'manager') {
            permission = 'manage_dropzone';
          }

          //2014-02-03 00:00:00.000000
          //var permission_valid_to = expires.getFullYear()+'-'+expires.getMonth()+'-'+expires.getDate();
          var permissionObject = {
            "user_email": email,
            "object_id": this.get('selectedDropzone.id'),
            "type": "dropzone",
            "permission_name": permission
          };
          if (expires) {
            permissionObject.permission_valid_to = expires;
          }
          var permissionRecord = this.get('store').createRecord('permission', permissionObject);
          var that = this;
          permissionRecord.save().then(function () {
            presenter.accept();
            that.clearFields();
          });
        }
      },
      deleteUser: function deleteUser(id) {
        console.info('deleteOwner ' + id);
        this.get('store').findRecord('permission', id, {
          backgroundReload: false
        }).then(function (permission) {
          permission.destroyRecord(); // => DELETE to /posts/2
        });
      },
      cancel: function cancel(presenter) {
        //this.get("model").rollbackAttributes();
        this.clearFields();
        presenter.decline();
      },
      addPublicUrl: function addPublicUrl() {
        console.log('addPublicUrl');
        var that = this;
        var dzAlias = this.store.createRecord('dropzone-id-alias', {
          dropzone: this.get('selectedDropzone.id'),
          dz_id_alias: that.guid()
        });
        dzAlias.save().then(function () {
          that.set('refreshAliases', !that.get('refreshAliases'));
        });
      },
      deletePublicUrl: function deletePublicUrl() {
        console.log('deletePublicUrl');
        this.get('publicUrls').forEach(function (publicUrl) {
          publicUrl.destroyRecord();
        });
      },
      saveWUStation: function saveWUStation() {
        var that = this;
        var verifySuccess = function verifySuccess() {
          that.set('verify_wu_error', false);
          that.store.findRecord('dropzone', that.get('selectedDropzone.id')).then(function (dropzone) {
            dropzone.save();
          });
        };
        var verifyError = function verifyError() {
          that.set('verify_wu_error', true);
        };
        (0, _requestWuWeather.default)(this.get('selectedDropzone.dz_wu_station_id'), this.get('selectedDropzone.dz_wu_api_key'), verifySuccess, verifyError);
      }
    },
    guid: function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },
    publicUrls: Ember.computed('selectedDropzone', 'refreshAliases', function () {
      return this.store.query('dropzone-id-alias', {
        dz_id: this.get('selectedDropzone.id')
      });
    }),
    hasPublicUrls: Ember.computed('publicUrls.[]', function () {
      return this.get('publicUrls').length > 0;
    }),
    clearFields: function clearFields() {
      this.set('email', "");
      this.set('expires', null);
      this.set('userType', null);
    },
    saveDisabled: Ember.computed('email', 'expires', function () {
      var email = this.get("email");
      //var expires = this .get("expires");

      //return isEmpty(email) || isEmpty(expires);
      return Ember.isEmpty(email);
    }),
    administeredDropzones: Ember.computed('all_permissions', function () {
      return _permissionsUtils.default.findAdministeredDropzones(this.get('session'), this.get('store'));
    }),
    showUsers: Ember.computed('canAdministerSelectedDropzoneOwners', 'canAdministerSelectedDropzoneManagers', 'isSelectedDropzoneOwner', 'isSelectedDropzoneManager', function () {
      return this.get('canAdministerSelectedDropzoneOwners') || this.get('canAdministerSelectedDropzoneManagers') || this.get('isSelectedDropzoneOwner') || this.get('isSelectedDropzoneManager');
    }),
    canAdministerSelectedDropzoneOwners: Ember.computed('session.userAcl.hasAdministerDropzoneOwnersAccess', 'selectedDropzone', function () {
      if (this.get('session.userAcl.hasAdministerDropzoneOwnersAccess')) {
        return this.get('selectedDropzone') != null;
      } else {
        return null;
      }
    }),
    isSelectedDropzoneOwner: Ember.computed('session.userAcl.hasAdministerDropzoneOwnersAccess', 'selectedDropzone', function () {
      if (this.get('session.userAcl.hasAdministerDropzoneOwnersAccess')) {
        return this.get('selectedDropzone') != null;
      } else {
        var permissions = _permissionsUtils.default.findMyPermissionsWithName(this.get('session'), 'administer_dropzone', this.get('selectedDropzone'));
        return !Ember.isEmpty(permissions);
      }
    }),
    canAdministerSelectedDropzoneManagers: Ember.computed('selectedDropzone', 'canAdministerSelectedDropzoneOwners', function () {
      if (this.get('session.userAcl.hasAdministerDropzoneOwnersAccess')) {
        return this.get('selectedDropzone') != null;
      } else {
        return this.get('isSelectedDropzoneOwner');
      }
    }),
    isSelectedDropzoneManager: Ember.computed('session.userAcl.hasAdministerDropzoneOwnersAccess', 'selectedDropzone', function () {
      if (this.get('session.userAcl.hasAdministerDropzoneOwnersAccess') || this.get('isSelectedDropzoneOwner')) {
        return this.get('selectedDropzone') != null;
      } else {
        var permissions = _permissionsUtils.default.findMyPermissionsWithName(this.get('session'), 'manage_dropzone', this.get('selectedDropzone'));
        return !Ember.isEmpty(permissions);
      }
    }),
    dropzoneOwners: Ember.computed('canAdministerSelectedDropzoneOwners', 'all_permissions.length', function () {
      if (this.get('isSelectedDropzoneOwner')) {
        return _permissionsUtils.default.findPermissionsWithName(this.get('session'), 'administer_dropzone', this.get('selectedDropzone'));
      } else {
        return null;
      }
    }),
    dropzoneManagers: Ember.computed('canAdministerSelectedDropzoneManagers', 'all_permissions.length', function () {
      if (this.get('isSelectedDropzoneManager')) {
        return _permissionsUtils.default.findPermissionsWithName(this.get('session'), 'manage_dropzone', this.get('selectedDropzone'));
      } else {
        return null;
      }
    })
  });
});