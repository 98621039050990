define("spotassist/utils/copy-dropzone-to-dropzone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = copyDropzoneToDropzone;
  function copyDropzoneToDropzone(srcDz, dstDz) {
    //var modelKeys = keys(srcDz.toJSON());
    //modelKeys.forEach(function(modelKey) {

    //var changedAttrs = srcDz.changedAttributes();
    //srcDz.constructor.eachAttribute(function(modelKey/*, meta*/) {
    //  if(changedAttrs[modelKey]) {
    //    dstDz.set(modelKey, changedAttrs[modelKey][1]);
    //  }
    //});

    srcDz.constructor.eachAttribute(function (modelKey /*, meta*/) {
      dstDz.set(modelKey, srcDz.get(modelKey));
    });

    /*
    dstDz.set('dz_name', srcDz.get('dz_name'));
    dstDz.set('dz_address', srcDz.get('dz_address'));
    dstDz.set('lat', srcDz.get('lat'));
    dstDz.set('lon', srcDz.get('lon'));
    dstDz.set('dz_phone', srcDz.get('dz_phone'));
    dstDz.set('dz_email', srcDz.get('dz_email'));
    dstDz.set('dz_url', srcDz.get('dz_url'));
    dstDz.set('facebook_url', srcDz.get('facebook_url'));
    dstDz.set('dz_elevation_m', srcDz.get('dz_elevation_m'));
    dstDz.set('dz_landing_corridor', srcDz.get('dz_landing_corridor'));
    dstDz.set('dz_pattern_strategy', srcDz.get('dz_pattern_strategy'));
    dstDz.set('dz_downwind_ft', srcDz.get('dz_downwind_ft'));
    dstDz.set('dz_base_ft', srcDz.get('dz_base_ft'));
    dstDz.set('dz_final_ft', srcDz.get('dz_final_ft'));
    dstDz.set('dz_ident', srcDz.get('dz_ident'));
    dstDz.set('active', srcDz.get('active'));
    dstDz.set('dz_outs', srcDz.get('dz_outs'));
    */
  }
});