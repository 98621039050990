define("spotassist/adapters/application", ["exports", "spotassist/adapters/drf", "spotassist/config/environment"], function (_exports, _drf, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import JSONAPIAdapter from 'ember-data/adapters/json-api';
  //import DS from 'ember-data';
  //export default DS.RESTAdapter.extend({
  /*
  export default JSONAPIAdapter.extend({
    host: 'http://127.0.0.1:8000',
    namespace: '',
    headers: {
      //'API_KEY': 'secret key',
        //'ANOTHER_HEADER': 'Some header value'
      'Accept':'application/json',
      'Authorization':'Token '+"57b5b40dde9ca71cc6647f3b0bec26937dfa6221"
    },
    buildURL: function(record, suffix) {
      return this._super(record,suffix) + '/?format=json'
    },
  
    pathForType: function(type) {
      return Ember.String.underscore(type).pluralize();
      //return Ember.String.dasherize(type).pluralize();
    }
  
  });
  */
  var _default = _exports.default = _drf.default.extend({
    host: _environment.default.adapter_host,
    //headers: {
    //'API_KEY': 'secret key',
    //'ANOTHER_HEADER': 'Some header value'
    //'Accept':'application/json',
    //'Authorization':'Token '+"57b5b40dde9ca71cc6647f3b0bec26937dfa6221"
    //},

    session: Ember.inject.service('session'),
    headers: Ember.computed('session', 'session.isAuthenticated', function () {
      //if(this.get('session.currentUser')) {
      var headers = {};
      if (this.get('session.isAuthenticated') && !Ember.isEmpty(this.get('session.data.authenticated.token'))) {
        var token = this.get('session.data.authenticated.token');
        headers = {
          'Authorization': 'Token ' + token
        };
      }
      Ember.$.ajaxSetup({
        headers: headers
      });
      return headers;
    })
    /*
    headers: computed('session.curentUser', function() {
      if(this.get('session.currentUser')) {
        return {
            'Authorization':'Token '+this.get('session.currentUser.token')
        };
      }
    })
    */

    //authorizer: 'authorizer:drf-token-authorizer',
  });
});