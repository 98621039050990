define("spotassist/objects/user-acl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UserAcl = Ember.Object.extend({
    findPermissionsWithName: function findPermissionsWithName(permissionName, dropzone_id) {
      var allDropzonesPermissions = this.get('permissions').filterBy('permission_name', permissionName).filterBy('type', 'dropzone');

      //var thisDropzonePermissions = allDropzonesPermissions.filterBy('object_id', parseInt(dropzone_id));
      var thisDropzonePermissions = allDropzonesPermissions.filterBy('object_id', dropzone_id);
      return thisDropzonePermissions;
    },
    hasCrowdToolAccess: Ember.computed('permissions', function () {
      var permissions = this.get('permissions').filterBy('permission_name', 'administer_crowd_tool');
      return !Ember.isEmpty(permissions);
    }),
    hasAdministerDropzoneOwnersAccess: Ember.computed('permissions', function () {
      var permissions = this.get('permissions').filterBy('permission_name', 'administer_dropzone_owners');
      return !Ember.isEmpty(permissions);
    }),
    hasDropzoneWeatherCorrectionAccess: function hasDropzoneWeatherCorrectionAccess(dropzone_id) {
      if (this.get('hasAdministerDropzoneOwnersAccess')) {
        return true;
      } else {
        var administerPermissions = this.findPermissionsWithName("administer_dropzone", dropzone_id);
        var managePermissions = this.findPermissionsWithName("manage_dropzone", dropzone_id);
        if (!Ember.isEmpty(administerPermissions)) {
          return true;
        } else if (!Ember.isEmpty(managePermissions)) {
          return true;
        } else {
          return false;
        }
      }
    },
    hasDropzoneBigPictureAccess: function hasDropzoneBigPictureAccess(dropzone_id) {
      return dropzone_id === "13" || this.hasDropzoneWeatherCorrectionAccess(dropzone_id);
    },
    hasDropzoneEditAccess: function hasDropzoneEditAccess(dropzone_id) {
      return this.hasDropzoneWeatherCorrectionAccess(dropzone_id);
    }
  });
  var _default = _exports.default = UserAcl;
});