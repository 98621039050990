define("spotassist/templates/layouts/blank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "b9u0D0l3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"ember-dialog-dialog \",[21,\"className\"],\" \",[27,\"if\",[[23,[\"substrate\"]],\"substrate\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dialog-content\"],[11,\"tabindex\",\"-1\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"templateName\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"dialog-body\"],[9],[15,[23,[\"templateName\"]],[]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"component\",[\"dialog-body\"],[[\"layout\",\"contextObject\",\"context\",\"class\"],[[23,[\"template\"]],[23,[\"contextObject\"]],[23,[\"context\"]],\"dialog-body\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\" \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "spotassist/templates/layouts/blank.hbs"
    }
  });
});