define("spotassist/templates/tutorials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "P34tDUI/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"Watch and learn\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"text-muted\"],[9],[0,\"These videos will help you to get started.\"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-sm-6\"],[9],[0,\"\\n      \"],[2,\" 16:9 \"],[0,\"\\n      \"],[7,\"h4\"],[9],[0,\"Spot Assist Big Picture QuickStart\"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"embed-responsive embed-responsive-16by9\"],[9],[0,\"\\n        \"],[7,\"iframe\"],[11,\"class\",\"embed-responsive-item\"],[11,\"src\",\"//www.youtube.com/embed/PSVRQ2XQ9M8\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-sm-6\"],[9],[0,\"\\n      \"],[2,\" 16:9 \"],[0,\"\\n      \"],[7,\"h4\"],[9],[0,\"Big Picture Dropzone Setup\"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"embed-responsive embed-responsive-16by9\"],[9],[0,\"\\n        \"],[7,\"iframe\"],[11,\"class\",\"embed-responsive-item\"],[11,\"src\",\"//www.youtube.com/embed/lYHhD16e8uY\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-sm-6\"],[9],[0,\"\\n      \"],[2,\" 16:9 \"],[0,\"\\n      \"],[7,\"h4\"],[9],[0,\"Big Picture Pattern Lock View\"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"embed-responsive embed-responsive-16by9\"],[9],[0,\"\\n        \"],[7,\"iframe\"],[11,\"class\",\"embed-responsive-item\"],[11,\"src\",\"//www.youtube.com/embed/t7-3lefH30I\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/tutorials.hbs"
    }
  });
});