define("spotassist/controllers/dropzones/crowd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    filterText: null,
    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 10,
    //totalPages: 5,
    init: function init() {
      this._super();
    },
    filterTextObserver: Ember.observer('filterText', function () {
      //this.get('model.otherParams').filter = this.get('filterText');
      this.get('model').setOtherParam('filter', this.get('filterText'));
      //this.get('model').fetchContent();
      if (this.get('model').get('page') !== 1) {
        this.get('model').set('page', 1);
      } else {
        this.get('model').set('promise', this.get('model').fetchContent());
      }
    }),
    actions: {
      panelClicked: function panelClicked(crowdDropzone) {
        if (!crowdDropzone.get('unregistered')) {
          var masterDropzone = this.store.peekRecord('dropzone', crowdDropzone.get('dz_id'));
          if (!masterDropzone) {
            crowdDropzone.set('loadingDifferences', true);
            this.store.findRecord('dropzone', crowdDropzone.get('dz_id')).then(function (dropzone) {
              crowdDropzone.set('loadingDifferences', false);
              crowdDropzone.set('parentDropzone', dropzone);
            });
          } else {
            crowdDropzone.set('parentDropzone', masterDropzone);
          }
        }
      },
      openNavAction: function openNavAction() {
        this.get('user-settings').openNav();
      },
      closeNavAction: function closeNavAction() {
        this.get('user-settings').closeNav();
      }
    },
    loadDropzones: function loadDropzones() {
      this.store.findAll('dropzone', {
        reload: true,
        null_location: "true"
      });
    }
  });
});