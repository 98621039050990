define("spotassist/initializers/stripe", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];
    var _config$stripe = _environment.default.stripe,
      stripe = _config$stripe === void 0 ? {} : _config$stripe;
    application.register('config:stripe', stripe, {
      instantiate: false
    });
    application.inject('service:stripe', 'stripeConfig', 'config:stripe');
  }
  var _default = _exports.default = {
    name: 'stripe',
    initialize: initialize
  };
});