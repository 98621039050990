define("spotassist/controllers/dropzones/bigpicture", ["exports", "spotassist/config/environment", "spotassist/utils/weather-utils", "spotassist/utils/calc-utils", "spotassist/utils/conversion-utils", "spotassist/objects/dropzone-object", "spotassist/mixins/requestable-weather", "spotassist/mixins/savable-dropzone", "spotassist/utils/url-parameter-by-name"], function (_exports, _environment, _weatherUtils, _calcUtils, _conversionUtils, _dropzoneObject, _requestableWeather, _savableDropzone, _urlParameterByName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global L */
  /* global com */

  var EMPTY_ARRAY = [];
  var WindLine = Ember.Object.extend({
    /*
    lat
    lon
    rotation
     */
    location: Ember.computed('lat', 'lon', function () {
      return [this.get('lat'), this.get('lon')];
    }),
    icon: Ember.computed(function () {
      var zoom = this.get('zoomSource.zoom');
      var iconSizeRatio = 0;
      if (zoom <= 15) {
        iconSizeRatio = 0;
      }
      if (zoom == 16) {
        iconSizeRatio = 5;
      }
      if (zoom == 17) {
        iconSizeRatio = 10;
      }
      if (zoom == 18) {
        iconSizeRatio = 20;
      }

      //console.log("zoom="+zoom+" iconSizeRatio="+iconSizeRatio);

      var windMultiplier = 1.5;
      if (this.get('windType') === 'wind') {
        var windspeedAvg = this.get('imperial.windspeedAvg');
        //windspeedAvg = 20;
        return new L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/wind-line.png',
          iconSize: [2, windMultiplier * iconSizeRatio * windspeedAvg],
          iconAnchor: [0, 0],
          className: 'wind-line-opacity'
        });
      }
      if (this.get('windType') === 'gust') {
        var windgustAvg = this.get('imperial.windgustAvg');
        //windgustAvg = 30;
        return new L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/gust-line.png',
          iconSize: [2, windMultiplier * iconSizeRatio * windgustAvg],
          iconAnchor: [0, 0],
          className: 'wind-gust-opacity'
        });
      }
    }),
    rotation: Ember.computed('winddirAvg', function () {
      return this.get('winddirAvg') - 180;
      //return 0;
    }),
    popupOffset: Ember.computed('rotation', function () {
      return new L.Point(0, -50);
    })
  });
  var _default = _exports.default = Ember.Controller.extend(_requestableWeather.default, _savableDropzone.default, {
    session: Ember.inject.service('session'),
    tab: "weather",
    googleHybrid: L.tileLayer('//{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
      //maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }),
    zoom: 15,
    highlights: EMPTY_ARRAY,
    lastHighlight: null,
    runShowcase: true,
    init: function init() {
      this._super();
      var session = this.get('session.user');
      if (!session) {
        console.warn("no session");
      }
    },
    actions: {
      setPreferredWindaloft: function setPreferredWindaloft(windaloftStation) {
        console.info("setPreferredWindaloft " + windaloftStation.station_name);
      },
      refreshWeather: function refreshWeather() {
        /*
        this.get('highlights').forEach(function(highlight) {
          highlight.target.remove();
        });
        */
        this.requestCurrentDropzoneWeather();
      },
      selectDzTabAction: function selectDzTabAction(tabName) {
        this.set('tab', tabName);
      },
      savePatternBounds: function savePatternBounds() {
        var map = this.get("map");
        var bounds = map.getBounds();
        //var box = bounds.toBBoxString();
        var southWest = bounds.getSouthWest();
        var northEast = bounds.getNorthEast();
        var patternBounds = [[southWest.lat, southWest.lng], [northEast.lat, northEast.lng]];
        var patternBoundsString = JSON.stringify(patternBounds);
        this.set('selectedDropzone.dz_pattern_bounds', patternBoundsString);
        this.get('selectedDropzone').save();
      },
      resetPatternBounds: function resetPatternBounds() {
        this.set('selectedDropzone.dz_pattern_bounds', null);
        this.get('selectedDropzone').save();
      },
      loadSidebar: function loadSidebar(map) {
        L.control.zoom({
          position: 'bottomright'
        }).addTo(map.target);

        //L.control.layers(null, overlayPane, {position: 'topleft'}).addTo(map.target);c

        //workaround for draggable
        map.target.eachLayer(function (layer) {
          //layer.bindPopup('Hello');
          //console.log(""+layer.name);
          if (layer.options) {
            layer.options.removeOutsideVisibleBounds = false;
          }
        });
        this.set('map', map.target);
        map.target.addLayer(this.googleHybrid);
        var that = this;
        map.target.on('zoom', function () {
          that.set('zoom', map.target.getZoom());
        });
        map.target.on('layeradd', function (highlight) {
          //console.log(JSON.stringify(highlight.layer.options));

          //if(highlight.layer.togglePopup) {
          if (highlight.layer.options.clickable) {
            that.get('highlights').addObject(highlight.layer);
            //console.info('layeradd highlights='+that.get('highlights').length);
          }
        });
        map.target.on('layerremove', function (highlight) {
          that.get('highlights').removeObject(highlight.layer);
          //console.info('layerremove highlights='+that.get('highlights').length);
        });
      },
      setDropzonePatternStrategy: function setDropzonePatternStrategy(patternStrategy) {
        console.info('setDropzonePatternStrategy - ' + patternStrategy);
        this.set('selectedDropzone.dz_pattern_strategy', patternStrategy.value);
        var that = this;
        this.get('selectedDropzone').save().then(function () {
          that.requestCurrentDropzoneWeather();
        });
      },
      setModeAction: function setModeAction(mode) {
        if (mode === 'showcase') {
          this.setMode('range');
          this.set('runShowcase', true);
          this.featuresHighlighterTimer();
        } else if (mode === 'range') {
          this.setMode('range');
          this.set('runShowcase', false);
          this.beautifyModeScreen();
        } else if (mode === 'outs') {
          this.setMode('outs');
          this.set('runShowcase', false);
          this.beautifyModeScreen();
        } else if (mode === 'pattern') {
          this.setMode('pattern');
          this.set('runShowcase', false);
          this.beautifyModeScreen();
        }
      }
      /*
      highlightAdded(label, highlight) {
        console.log("highlightAdded label="+label);
        if(highlight.target._latlngs) {
          highlight.polygon =true;
        } else {
          highlight.polygon =false;
        }
         this.get('highlights').addObject(highlight);
         var sortedHighlights = this.get('highlights').sortBy('polygon');
        this.set('highlights', sortedHighlights);
      },
      highlightRemoved() {
        console.info("highlightRemoved")
      //highlightRemoved(highlight) {
        //console.log("highlightRemoved");
        //this.get('highlights').removeObject(highlight);
      }
      */
    },
    weatherTabSelected: Ember.computed('tab', function () {
      return this.get('tab') === 'weather';
    }),
    toolsTabSelected: Ember.computed('tab', function () {
      return this.get('tab') === 'tools';
    }),
    viewTabSelected: Ember.computed('tab', function () {
      return this.get('tab') === 'view';
    }),
    dzOutsLocations: Ember.computed('selectedDropzone.dz_outs_shapes', 'windaloftStation', 'metarStation', 'metarStation.metarObject', function () {
      //console.info('dzOutsLocations');
      var locations = [];
      if (this.get('selectedDropzone.dz_outs_shapes')) {
        this.get('selectedDropzone.dz_outs_shapes').forEach(function (shape) {
          shape.geometry.coordinates.forEach(function (coordinates) {
            coordinates.forEach(function (coordinate) {
              locations.pushObject([coordinate[1], coordinate[0]]);
            });
          });
        });
      }
      return locations;
    }),
    rangeCalculated: Ember.computed('polygon1kLocations', function () {
      var polygon1kLocations = this.get('polygon1kLocations');
      return polygon1kLocations.length > 0;
    }),
    polygon1kCenter: Ember.computed('polygon1kLocations', function () {
      var polygon1kLocations = this.get('polygon1kLocations');
      if (polygon1kLocations.length > 0) {
        var latlngs = polygon1kLocations.map(function (r) {
          return [r.lat, r.lng];
        });
        var polygon = L.polygon(latlngs);
        var center = polygon.getBounds().getCenter();
        return center;
      } else {
        return null;
      }
    }),
    polygon1kLocations: Ember.computed('windaloftStation', 'metarStation', 'metarStation.metarObject', function () {
      //return this.get('restaurants').map(r => ({lat: r.lat, lng: r.lng}));
      var windaloft = _weatherUtils.default.windaloftFromStation(this.get('windaloftStation'), this.get('rawinsondeStation'));
      var metar = _weatherUtils.default.metarFromStation(this.get('metarStation'));
      var locations = [];
      if (windaloft && metar) {
        var dropzone = this.get('selectedDropzone');
        var altitudeFromFt = _environment.default.CONSTANTS.OPEN_ALTITUDE;
        var altitudeToFt = _environment.default.CONSTANTS.PATTERN_ALTITUDE;
        var polygonMapPoints = _calcUtils.default.calcCanopyRange(dropzone, altitudeFromFt, altitudeToFt, windaloft, metar);
        locations = polygonMapPoints.array.map(function (r) {
          return {
            lat: r.latitude,
            lng: r.longitude
          };
        });
      }
      //console.info('polygon1kLocations: locations='+locations.length);
      return locations;
    }),
    polygonSurfaceLocations: Ember.computed('windaloftStation', 'metarStation', 'metarStation.metarObject', 'localSettings.settings.speed_unit', function () {
      var windaloft = _weatherUtils.default.windaloftFromStation(this.get('windaloftStation'), this.get('rawinsondeStation'));
      var metar = _weatherUtils.default.metarFromStation(this.get('metarStation'));
      var locations = [];
      if (windaloft && metar) {
        var dropzone = this.get('selectedDropzone');
        var altitudeFromFt = _environment.default.CONSTANTS.OPEN_ALTITUDE;
        var altitudeToFt = 0;
        var polygon1kMapPoints = _calcUtils.default.calcCanopyRange(dropzone, altitudeFromFt, altitudeToFt, windaloft, metar);
        locations = polygon1kMapPoints.array.map(function (r) {
          return {
            lat: r.latitude,
            lng: r.longitude
          };
        });
      }

      //console.info('polygonSurfaceLocations: locations='+locations.length);
      return locations;
    }),
    canopyRangeWinds: Ember.computed('windaloftStation', 'metarStation', 'metarStation.metarObject', 'localSettings.settings.speed_unit', function () {
      var _this = this;
      //console.info('canopyRangeWinds');
      var windaloft = _weatherUtils.default.windaloftFromStation(this.get('windaloftStation'), this.get('rawinsondeStation'));
      var metar = _weatherUtils.default.metarFromStation(this.get('metarStation'));
      if (windaloft && metar) {
        var dropzone = this.get('selectedDropzone');
        var altitudeTop = _environment.default.CONSTANTS.OPEN_ALTITUDE;
        var altitudeBottom = 0;
        var windArrows = _calcUtils.default.calcCanopyRangeWinds(dropzone, altitudeTop, altitudeBottom, windaloft, metar);
        var icon = L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/wind_arrow.png'
        });
        var arrows = windArrows.array.map(function (r) {
          return {
            location: {
              lat: r.location.latitude,
              lng: r.location.longitude
            },
            icon: icon,
            rotation: r.flightUnit.windUnit.windAngleFrom,
            title: "Wind at " + _conversionUtils.default.convertAltitude(r.flightUnit.topAltitudeFt, 'ft', _this.get('localSettings').get('settings.altitude_unit')),
            snippet: _conversionUtils.default.convertSpeed(r.flightUnit.windUnit.windSpeedMs, 'm/s', _this.get('localSettings').get('settings.speed_unit')) + " from " + r.flightUnit.windUnit.windAngleFrom + String.fromCharCode(176) + (r.flightUnit.windUnit.windGustSpeedKt ? " Gusts " + _conversionUtils.default.convertSpeed(r.flightUnit.windUnit.windGustSpeedKt, 'kts', _this.get('localSettings').get('settings.speed_unit')) : "")
          };
        });
        return arrows;
      } else {
        return [];
      }
    }),
    patternData: Ember.computed('selectedDropzone.{lat,lon,dz_landing_corridor,dz_pattern_strategy}', 'metarStation', 'metarStation.metarObject', function () {
      var metar = _weatherUtils.default.metarFromStation(this.get('metarStation'));
      if (metar) {
        var dropzone = this.get('selectedDropzone');
        var patternData = _calcUtils.default.calcPattern(dropzone, metar);
        return patternData;
      } else {
        return null;
      }
    }),
    getPointImage: function getPointImage(pointNumber) {
      if (pointNumber === 1) {
        return _environment.default.rootURL + 'assets/images/point1.png';
      } else if (pointNumber === 2) {
        return _environment.default.rootURL + 'assets/images/point2.png';
      } else if (pointNumber === 3) {
        return _environment.default.rootURL + 'assets/images/point3.png';
      }
    },
    patternPoints: Ember.computed('patternData', 'localSettings.settings.altitude_unit', function () {
      var _this2 = this;
      var patternData = this.get('patternData');
      var dropzoneObject = _dropzoneObject.default.create({
        dropzone: this.get('selectedDropzone')
      });
      if (patternData && patternData.patternPointData.array.length > 0) {
        var markersArray = patternData.patternPointData.array.filter(function (element) {
          return element.isMarker();
        }).reverse();
        var points = markersArray.map(function (r, index) {
          return {
            location: {
              lat: r.location.latitude,
              lng: r.location.longitude
            },
            icon: L.icon({
              iconUrl: _this2.getPointImage(index + 1),
              iconAnchor: [16, 35],
              popupAnchor: [0, -35]
            }),
            title: r.patternPoint.pointName,
            snippet: "Turn Altitude: " + _conversionUtils.default.convertAltitude(r.patternPoint.getTurnAltitude(dropzoneObject), 'ft', _this2.get('localSettings').get('settings.altitude_unit'))
          };
        });
        return points;
      } else {
        return [];
      }
    }),
    patternPointsLocations: Ember.computed('patternData', function () {
      var patternData = this.get('patternData');
      if (patternData && patternData.patternPointData.array.length > 0) {
        var patternPointsLocations = patternData.patternPointData.array.map(function (r) {
          return {
            lat: r.location.latitude,
            lng: r.location.longitude
          };
        });
        return patternPointsLocations;
      } else {
        return [];
      }
    }),
    rangeWinds: Ember.computed('wuObservations', function () {
      var wuObservations = this.get('wuObservations');
      if (!wuObservations) {
        return null;
      }
      var cutoffEpoch = Date.now() / 1000 - 2 * 60 * 60;
      var filteredObservations = wuObservations.observations.filter(function (observation) {
        return observation.epoch > cutoffEpoch;
      });
      var minWind = null;
      var maxWind = null;
      var minGust = null;
      var maxGust = null;
      filteredObservations.forEach(function (observation) {
        //observation.imperial.windspeedAvg = 10;
        //observation.imperial.windgustAvg = 20;

        if (minWind == null || minWind > observation.imperial.windspeedAvg) {
          minWind = observation.imperial.windspeedAvg;
        }
        if (maxWind == null || maxWind < observation.imperial.windspeedAvg) {
          maxWind = observation.imperial.windspeedAvg;
        }
        if (minGust == null || minGust > observation.imperial.windgustAvg) {
          minGust = observation.imperial.windgustAvg;
        }
        if (maxGust == null || maxGust < observation.imperial.windgustAvg) {
          maxGust = observation.imperial.windgustAvg;
        }
      });
      return {
        minWind: _conversionUtils.default.convertSpeed(minWind, 'kts', this.get('localSettings').get('settings.speed_unit')),
        maxWind: _conversionUtils.default.convertSpeed(maxWind, 'kts', this.get('localSettings').get('settings.speed_unit')),
        minGust: _conversionUtils.default.convertSpeed(minGust, 'kts', this.get('localSettings').get('settings.speed_unit')),
        maxGust: _conversionUtils.default.convertSpeed(maxGust, 'kts', this.get('localSettings').get('settings.speed_unit'))
      };
    }),
    windLines: Ember.computed('wuObservations', 'zoom', function () {
      var that = this;
      var windLines = [];
      var wuObservations = this.get('wuObservations');
      if (!wuObservations) {
        return windLines;
      }
      var cutoffEpoch = Date.now() / 1000 - 2 * 60 * 60;
      var filteredObservations = wuObservations.observations.filter(function (observation) {
        return observation.epoch > cutoffEpoch;
      });
      var minWind = null;
      var maxWind = null;
      var minGust = null;
      var maxGust = null;
      filteredObservations.forEach(function (observation) {
        //observation.imperial.windspeedAvg = 10;
        //observation.imperial.windgustAvg = 20;

        if (minWind == null || minWind > observation.imperial.windspeedAvg) {
          minWind = observation.imperial.windspeedAvg;
        }
        if (maxWind == null || maxWind < observation.imperial.windspeedAvg) {
          maxWind = observation.imperial.windspeedAvg;
        }
        if (minGust == null || minGust > observation.imperial.windgustAvg) {
          minGust = observation.imperial.windgustAvg;
        }
        if (maxGust == null || maxGust < observation.imperial.windgustAvg) {
          maxGust = observation.imperial.windgustAvg;
        }
      });
      filteredObservations.forEach(function (observation) {
        var windLine = WindLine.create(observation);
        windLine.set('lat', that.get("selectedDropzone.lat"));
        windLine.set('lon', that.get("selectedDropzone.lon"));
        windLine.set('minWind', minWind);
        windLine.set('maxWind', maxWind);
        windLine.set('minGust', minGust);
        windLine.set('maxGust', maxGust);
        windLine.set('zoomSource', that);
        if (observation.imperial.windspeedAvg > 0) {
          windLine.set('windType', "wind");
          windLines.push(windLine);
        }
        if (observation.imperial.windgustAvg > 0) {
          var gustLine = WindLine.create(windLine);
          gustLine.set('windType', "gust");
          windLines.push(gustLine);
        }
      });
      if (windLines.length > 0) {
        windLines[0].set('windPopup', true);
      }
      return windLines;
    }),
    patternLegs: Ember.computed('patternData', function () {
      var patternData = this.get('patternData');
      if (patternData && patternData.patternPointData.array.length > 0) {
        var markersArray = patternData.patternFlyData.array.reverse();
        var points = markersArray.map(function (r) {
          return {
            location: {
              lat: r.location.latitude,
              lng: r.location.longitude
            },
            icon: L.icon({
              iconUrl: _environment.default.rootURL + 'assets/images/canopy_white.png',
              iconAnchor: [24, 48],
              popupAnchor: [0, -24]
              //popupAnchor: [  (24*Math.cos(r.iconRotation)), -(24*Math.sin(r.iconRotation))],
            }),
            rotation: r.iconRotation,
            title: r.patternPoint.legName,
            snippet: r.crabAngle == 0 ? "Straight Flight" : "Crab Angle: " + (Math.abs(r.crabAngle) + String.fromCharCode(176) + (r.crabAngle < 0 ? " Right" : " Left"))
          };
        });
        return points;
      } else {
        return [];
      }
    }),
    patternWinds: Ember.computed('metarStation', 'metarStation.metarObject', 'localSettings.settings.speed_unit', function () {
      var metar = _weatherUtils.default.metarFromStation(this.get('metarStation'));
      if (metar) {
        var dropzone = this.get('selectedDropzone');
        var altitudeTop = 0;
        var altitudeBottom = 0;
        var windArrows = _calcUtils.default.calcCanopyRangeWinds(dropzone, altitudeTop, altitudeBottom, null, metar);
        var surfaceWindArrow = windArrows.array[0];
        var drawingWarnings = null;
        var flightUnits = com.livewings.spotassist.library.math.SkydiveMath.getAirColumnWinds(0, 0, null, metar, drawingWarnings);
        var surfaceFlightUnit = flightUnits.array[0];
        var shiftPoint = com.livewings.spotassist.library.math.SkydiveMath.translateWithWind(surfaceWindArrow.location, 40, 40, flightUnits.array[0]);
        /*
         var shiftXMeters = 10 * 1.5 * Math.sin(Math.toRadians(flightUnits.array[0].getWindUnit().getWindAngleFrom()));
        var shiftYMeters = 10 * 1.5 * Math.cos(Math.toRadians(flightUnits.array[0].getWindUnit().getWindAngleFrom()));
        var iconPoint = com.livewings.spotassist.library.tools.MapTools.translateCoordinates(shiftXMeters, shiftYMeters, new MapPoint(point.latitude, point.longitude));
        */

        var icon = L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/wind_arrow.png',
          //iconAnchor:   [-40, 100],
          //popupAnchor: [56, -100],
          popupAnchor: [16 * Math.cos(surfaceFlightUnit.windUnit.windAngleFrom), -(16 * Math.sin(surfaceFlightUnit.windUnit.windAngleFrom))]
        });
        var rangeWinds = this.get('rangeWinds');
        var rangeWindsString = "";
        var rangeGustsString = "";
        if (rangeWinds) {
          rangeWindsString += " Last hour: ";
          rangeWindsString += rangeWinds.minWind + "-" + rangeWinds.maxWind;
          if (rangeWinds.minGust && rangeWinds.maxGust) {
            rangeGustsString += " Last hour Gusts: ";
            rangeGustsString += rangeWinds.minGust + "-" + rangeWinds.maxGust;
          }
        }
        var arrows = [{
          location: {
            //lat: r.location.latitude,
            //lng: r.location.longitude
            lat: shiftPoint.latitude,
            lng: shiftPoint.longitude
          },
          icon: icon,
          rotation: surfaceFlightUnit.windUnit.windAngleFrom,
          title: "Wind at the surface",
          snippet: _conversionUtils.default.convertSpeed(surfaceFlightUnit.windUnit.windSpeedMs, 'm/s', this.get('localSettings').get('settings.speed_unit')) + " from " + surfaceFlightUnit.windUnit.windAngleFrom + String.fromCharCode(176) + (surfaceFlightUnit.windUnit.windGustSpeedKt ? " Gusts " + _conversionUtils.default.convertSpeed(surfaceFlightUnit.windUnit.windGustSpeedKt, 'kts', this.get('localSettings').get('settings.speed_unit')) : ""),
          snippet2: rangeWindsString,
          snippet3: rangeGustsString
        }];
        return arrows;
      } else {
        return [];
      }
    }),
    patternWindsLocations: Ember.computed('patternWinds', function () {
      var patternWinds = this.get('patternWinds');
      return patternWinds.map(function (r) {
        return {
          lat: r.location.lat,
          lng: r.location.lng
        };
      });
    }),
    setRouteActive: function setRouteActive(routeActive) {
      this.set('routeActive', routeActive);
      if (routeActive) {
        this.featuresHighlighterTimer();
        this.setTrialActive(true);
        //this.requestDropzoneWeatherTimer();
      }
    },
    publicModeChecker: Ember.observer('selectedDropzone', function () {
      var publicQueryParam = (0, _urlParameterByName.default)('public');
      var that = this;
      this.store.query('dropzone-id-alias', {
        dz_id: that.get('selectedDropzone.id')
      }).then(function (aliases) {
        aliases.forEach(function (alias) {
          if (alias.get('dz_id_alias') === publicQueryParam) {
            that.set('current_url_public', true);
            //console.log("current url is public.");
          }
        });
      });
    }),
    selectedDropzoneObserver: Ember.observer('selectedDropzone', function () {
      //this.get('highlights').clear();
      this.setMode('range');
    }),
    setMode: function setMode(mode) {
      if (this.get('mode') !== mode) {
        //console.info("setMode "+mode+" : clear highlights");
        //this.get('highlights').clear();
        this.set('mode', mode);
      }
    },
    modeRange: Ember.computed('mode', 'hasDropzonePermissions', function () {
      return this.get('hasDropzonePermissions') && this.get('mode') === 'range';
    }),
    modeOuts: Ember.computed('mode', 'hasDropzonePermissions', function () {
      return this.get('hasDropzonePermissions') && this.get('mode') === 'outs';
    }),
    modePattern: Ember.computed('mode', 'hasDropzonePermissions', function () {
      return this.get('hasDropzonePermissions') && this.get('mode') === 'pattern';
    }),
    modeShowcase: Ember.computed('runShowcase', 'hasDropzonePermissions', function () {
      return this.get('hasDropzonePermissions') && this.get('runShowcase') === true;
    }),
    modeScreenBeautifier: Ember.observer('modeRange', 'polygon1kLocations', 'polygonSurfaceLocations', 'patternPointsLocations', 'patternWindsLocations', 'selectedDropzone.dz_pattern_bounds', function () {
      this.beautifyModeScreen();
    }),
    beautifyModeScreen: function beautifyModeScreen() {
      var spotassistBoxElement = Ember.$("#omnibox");
      var locations = [];
      var dropzone = this.get('selectedDropzone');
      locations.pushObject([dropzone.get('lat'), dropzone.get('lon')]);
      if (this.get('modeRange')) {
        spotassistBoxElement.show();
        this.set('surfaceOnly', false);
        var polygon1kLocations = this.get('polygon1kLocations');
        locations.pushObjects(polygon1kLocations);
        var polygonSurfaceLocations = this.get('polygonSurfaceLocations');
        locations.pushObjects(polygonSurfaceLocations);
      } else if (this.get('modeOuts')) {
        spotassistBoxElement.hide();
        var dzOutsLocations = this.get('dzOutsLocations');
        locations.pushObjects(dzOutsLocations);
      } else if (this.get('modePattern')) {
        //spotassistBoxElement.hide();
        spotassistBoxElement.show();
        this.set('surfaceOnly', true);
        if (this.get('selectedDropzone.dz_pattern_bounds')) {
          var patternBounds = JSON.parse(this.get('selectedDropzone.dz_pattern_bounds'));
          var patternBoundsLocations = patternBounds.map(function (r) {
            return {
              lat: r[0],
              lng: r[1]
            };
          });
          locations.pushObjects(patternBoundsLocations);
        } else {
          var patternPointsLocations = this.get('patternPointsLocations');
          locations.pushObjects(patternPointsLocations);
          var patternWindsLocations = this.get('patternWindsLocations');
          locations.pushObjects(patternWindsLocations);
        }
      }
      if (locations.length > 0) {
        var map = this.get('map');
        var padding = {};
        if (!this.get('media.isMobile')) {
          if (!this.get('modePattern') || !this.get('selectedDropzone.dz_pattern_bounds')) {
            var paddingLeft = spotassistBoxElement.is(":visible") ? spotassistBoxElement.width() : 0;
            padding = {
              paddingTopLeft: L.point(paddingLeft, 50),
              maxZoom: 18
            };
          }
        }
        try {
          map.fitBounds(locations, padding);
          //map.fitBounds(locations);
        } catch (exception) {
          console.warn("exception durung beautifyModeScreen. Beautification will retry");
        }
      }
    },
    switchModes: function switchModes() {
      var nextMode = this.get('mode');
      if (this.get('mode') === 'range') {
        nextMode = this.get('dzOutsLocations').length > 0 ? 'outs' : "pattern";
      } else if (this.get('mode') === 'outs') {
        nextMode = 'pattern';
      } else if (!this.get('mode') || this.get('mode') === 'pattern') {
        nextMode = 'range';

        /*
        if(this.get('selectedDropzone')) {
          console.info('requesting dropzone updates');
          this.get('selectedDropzone').reload();
        }
        */
      }
      this.setMode(nextMode);
    },
    highlightFeature: function highlightFeature() {
      if (this.get('highlights').length > 0) {
        var highlightsRaw = this.get('highlights');
        var highlights = highlightsRaw.sortBy('options.attribution');
        var markerToHighlight = highlights[0];
        var currentIndex = highlights.indexOf(this.get('lastHighlight'));
        if (currentIndex >= 0 && currentIndex < highlights.length - 1) {
          markerToHighlight = highlights[currentIndex + 1];
        } else {
          this.beautifyModeScreen();
        }
        if (currentIndex === highlights.length - 1 || this.get('modeOuts') && currentIndex === 1) {
          this.switchModes();
        } else {
          console.info("highlightFeature " + (currentIndex + 1) + " out of " + this.get('highlights').length);
          var popupOpener = function popupOpener(markerToOpenPopup) {
            if (markerToOpenPopup._latlngs) {
              markerToOpenPopup.openPopup(markerToOpenPopup._latlngs[0][0]);
            } else if (markerToOpenPopup.togglePopup) {
              markerToOpenPopup.togglePopup();
            }
          };
          if (this.get('modeOuts')) {
            highlights.forEach(function (highlight) {
              popupOpener(highlight);
            });
          } else {
            popupOpener(markerToHighlight);
          }
        }
        this.set('lastHighlight', markerToHighlight);
      }
    },
    hasDropzonePermissions: Ember.computed('selectedDropzone', 'session.userAcl', 'trialActive', function () {
      if (this.get("selectedDropzone.id") === "13") {
        return true;
      }
      if (this.get('trialActive')) {
        return true;
      }
      if (this.get('current_url_public')) {
        return true;
      }
      if (!this.get("session.userAcl") || !this.get("session.userAcl").hasDropzoneBigPictureAccess(this.get("selectedDropzone.id"))) {
        return false;
      } else {
        return true;
      }
    }),
    setTrialActive: function setTrialActive(active) {
      this.set('trialActive', active);
      console.info("trialTimer. trial set to " + this.get('trialActive'));
      if (!this.get('routeActive')) {
        console.info("Route inactive. Stopping trialTimer");
        return;
      }
      if (!this.get('trialActive')) {
        console.info("Trial inactive. Stopping trialTimer");
        return;
      }
      Ember.run.later(this, function () {
        this.setTrialActive(false);
      }, _environment.default.CONSTANTS.TRIAL_PERIOUD);
    },
    featuresHighlighterTimer: function featuresHighlighterTimer() {
      //console.info("featuresHighlighterTimer ");
      if (!this.get('routeActive')) {
        console.info("Route inactive. Stopping featuresHighlighterTimer");
        return;
      }
      /*
      if(!this.get('hasDropzonePermissions')) {
        console.info("Doesn't have DropzonePermissions. Stopping featuresHighlighterTimer");
        return;
      }
      */

      if (this.get('runShowcase')) {
        //check if we get all weather
        var windaloft = _weatherUtils.default.windaloftFromStation(this.get('windaloftStation'), this.get('rawinsondeStation'));
        var metar = _weatherUtils.default.metarFromStation(this.get('metarStation'));
        if (windaloft && metar) {
          this.highlightFeature();
        }
        var timeout = 2 * 1500;
        /*
        if(this.get('highlights').length<=0) {
          timeout = 1000;
        }
        */

        Ember.run.later(this, function () {
          this.featuresHighlighterTimer();
        }, timeout);
      }
    },
    missingWeather: Ember.computed('windaloftStation', 'metarStation', function () {
      if (this.get('weatherLoadingProgress')) {
        return false;
      }
      var windaloft = _weatherUtils.default.windaloftFromStation(this.get('windaloftStation'), this.get('rawinsondeStation'));
      var metar = _weatherUtils.default.metarFromStation(this.get('metarStation'));
      return !windaloft || !metar;
    }),
    selectedDropzoneMapMover: Ember.observer('model', function () {
      //this.requestCurrentDropzoneWeather();
      this.requestDropzoneWeatherTimer();
      this.requestDropzoneUpdateTimer();
    }),
    requestDropzoneUpdateTimer: function requestDropzoneUpdateTimer() {
      if (!this.get('routeActive')) {
        console.info("Route inactive. Stopping requestDropzoneUpdateTimer");
        return;
      }
      var that = this;
      this.store.findRecord('dropzone', this.get('selectedDropzone.id')).then(function (dropzone) {
        that.set('model', dropzone);
      });
      var timeout = 1000 * 60;
      Ember.run.later(this, function () {
        this.requestDropzoneUpdateTimer();
      }, timeout);
    },
    requestDropzoneWeatherTimer: function requestDropzoneWeatherTimer() {
      if (!this.get('routeActive')) {
        console.info("Route inactive. Stopping dropzoneWeatherTimer");
        return;
      }
      this.requestCurrentDropzoneWeather();
      var timeout = 1000 * 60 * 10;
      //var timeout = 1000 * 60;

      Ember.run.later(this, function () {
        this.requestDropzoneWeatherTimer();
      }, timeout);
    },
    /*
    sessionUserObserver: observer('session.user', function() {
      console.info('get model id='+this.get('model.id'));
    }),
    selectedDropzone: computed('model', 'session.user', function() {
      console.info('get model id='+this.get('model.id'));
      return this.get('model');
    }),
    */

    /*
    selectedDropzoneSetter: observer('model', 'dropzones_service.dropzones', function() {
      var selectedDropzone = this.get('model');
       var oldSelectedDropzone = this.get('selectedDropzone');
      if(oldSelectedDropzone) {
        selectedDropzone = this.store.peekRecord('dropzone', oldSelectedDropzone.get('id'));
      }
       this.setMode('range');
      this.set('selectedDropzone', selectedDropzone);
    }),
    */
    selectedDropzone: Ember.computed('model', 'dropzones_service.dropzones', function () {
      var selectedDropzone = this.get('model');

      //var oldSelectedDropzone = this.get('selectedDropzone');
      if (selectedDropzone) {
        selectedDropzone = this.store.peekRecord('dropzone', selectedDropzone.get('id'));
      }
      this.setMode('range');
      return selectedDropzone;
    }),
    facebookCurrentDropzoneUrl: Ember.computed('selectedDropzone', function () {
      return _environment.default.SHARE_HOST + "/#!/dropzones/" + this.get('selectedDropzone.id') + "/bigpicture";
    }),
    currentDropzoneId: Ember.computed('selectedDropzone', function () {
      return this.get('selectedDropzone.id');
    }),
    requestCurrentDropzoneWeather: function requestCurrentDropzoneWeather() {
      var dropzone = this.get('selectedDropzone');

      //this.get('highlights').clear();

      this.requestDropzoneWeather(dropzone);
    }
  });
});