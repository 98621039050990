define("spotassist/objects/gfs-cloud-level-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import ENV from "spotassist/config/environment";

  /* global com */

  var GfsCloudLevel = Ember.Object.extend({
    percent: null,
    height_m: null,
    init: function init() {
      this._super();
      this.set('__interfaces', "com.livewings.spotassist.library.gfs.IGfsCloudLevel");
    },
    getPercent: function getPercent() {
      return this.get('percent');
    },
    getHeight_m: function getHeight_m() {
      return this.get('height_m');
    }
  });
  var _default = _exports.default = GfsCloudLevel;
});