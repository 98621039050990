define("spotassist/routes/usage", ["exports", "spotassist/mixins/trackable-route", "spotassist/mixins/seo-route"], function (_exports, _trackableRoute, _seoRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_trackableRoute.default, _seoRoute.default, {
    actions: {
      openNavAction: function openNavAction() {
        this.get('user-settings').openNav();
      },
      closeNavAction: function closeNavAction() {
        this.get('user-settings').closeNav();
      },
      mapClicked: function mapClicked() {
        //console.log('mapClicked: ');

        //this.controller.set('markerClicked', false);
        //this.controller.set('selectedDropzone', null);
        this.get('user-settings').closeNav();
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        this.trackPage();
      }
    },
    afterModel: function afterModel(model) {
      var title = "App Usage";
      var description = "App Usage";
      var imageUrl = 'http://www.spotassist.com/wp-content/uploads/2017/02/dropzones-spotassist.png';
      this.setHeadTags(model, title, description, imageUrl);
    }
  });
});