define("spotassist/objects/metar-station", ["exports", "spotassist/objects/metar"], function (_exports, _metar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MetarStation = Ember.Object.extend({
    init: function init() {
      this._super();
      this.set('metarObject', _metar.default.create(this.get('metar')[0]));
    }

    /*
    metarValue: computed('metar.@each', function() {
      return this.get('metar')[0];
    }),
    */
  });
  var _default = _exports.default = MetarStation;
});