define("spotassist/torii-providers/google-token", ["exports", "torii/configuration", "torii/providers/oauth2-bearer"], function (_exports, _configuration, _oauth2Bearer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var REQUIRED_URL_PARAMS = ['state'];
  var OPTIONAL_URL_PARAMS = ['scope', 'request_visible_actions', 'access_type'];
  var RESPONSE_PARAMS = ['token'];
  var GoogleToken = _oauth2Bearer.default.extend({
    name: 'google-token',
    baseUrl: 'https://accounts.google.com/o/oauth2/auth',
    // additional params that this provider requires
    requiredUrlParams: REQUIRED_URL_PARAMS,
    optionalUrlParams: OPTIONAL_URL_PARAMS,
    requestVisibleActions: (0, _configuration.configurable)('requestVisibleActions', ''),
    accessType: (0, _configuration.configurable)('accessType', ''),
    responseParams: RESPONSE_PARAMS,
    scope: (0, _configuration.configurable)('scope', 'email'),
    state: (0, _configuration.configurable)('state', 'STATE'),
    redirectUri: (0, _configuration.configurable)('redirectUri', 'http://localhost:8000/oauth2callback'),
    open: function open() {
      var name = this.get('name'),
        url = this.buildUrl(),
        redirectUri = this.get('redirectUri'),
        responseParams = this.get('responseParams');

      //var client_id = this.get('client_id');

      return this.get('popup').open(url, responseParams).then(function (authData) {
        var missingResponseParams = [];
        responseParams.forEach(function (param) {
          if (authData[param] === undefined) {
            missingResponseParams.push(param);
          }
        });
        if (missingResponseParams.length) {
          throw "The response from the provider is missing " + "these required response params: " + responseParams.join(', ');
        }
        return Ember.$.get("https://www.googleapis.com/plus/v1/people/me", {
          access_token: authData.token
        }).then(function (user) {
          return {
            userName: user.displayName,
            userEmail: user.emails[0].value,
            provider: name,
            redirectUri: redirectUri
          };
        });
      });
    }
  });
  var _default = _exports.default = GoogleToken;
});