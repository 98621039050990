define("spotassist/templates/raob-stations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TpNlufpU",
    "block": "{\"symbols\":[\"context\",\"raob-station\"],\"statements\":[[7,\"button\"],[9],[0,\"Remove All\"],[3,\"action\",[[22,0,[]],\"removeAll\"]],[10],[0,\"\\n\"],[7,\"button\"],[9],[0,\"Add All\"],[3,\"action\",[[22,0,[]],\"addAll\"]],[10],[0,\"\\n\"],[4,\"leaflet-map\",null,[[\"lat\",\"lng\",\"zoom\",\"maxZoom\"],[37.7833,-122.4167,1,25]],{\"statements\":[[0,\"\\n\\t\"],[1,[27,\"tile-layer\",null,[[\"url\"],[\"//{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png\"]]],false],[0,\"\\n\\n\"],[4,\"marker-cluster-layer\",null,null,{\"statements\":[[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[4,\"marker-layer\",null,[[\"location\"],[[22,2,[\"location\"]]]],{\"statements\":[[0,\"              \\t\"],[7,\"h3\"],[9],[1,[22,2,[\"name\"]],false],[10],[0,\"\\n              \\t\"],[1,[22,2,[\"site\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/raob-stations/index.hbs"
    }
  });
});