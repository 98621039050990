define("spotassist/mixins/savable-dropzone", ["exports", "spotassist/utils/global-error-handler", "spotassist/utils/copy-dropzone-to-dropzone"], function (_exports, _globalErrorHandler, _copyDropzoneToDropzone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    metrics: Ember.inject.service(),
    actions: {
      saveDropzoneAction: function saveDropzoneAction(dropzone) {
        //console.log('saveDropzone');
        //this.controller.set('dropzoneEditMode', true);
        //var model = this.controller.get('selectedDropzone');

        Ember.get(this, 'metrics').trackEvent({
          event: "Save Dropzone",
          dropzone: dropzone.get('displayName')
        });
        var that = this;
        var onSuccess = function onSuccess() /*dropzone*/{
          //dropzone.set('manual', false);
          that.set('dropzoneEditMode', false);
        };
        var onFail = function onFail(error) {
          // deal with the failure here
          //let model = that.controller.get('selectedDropzone');
          var message = 'saveDropzone failure:' + error.message;
          console.error(message + error);
          (0, _globalErrorHandler.default)(error, message);
        };

        //var session = this.get('session');
        //if(dropzone.constructor.modelName==='dropzone') {
        //if(session.get('user') && session.get('user').is_superuser) {
        if (dropzone.get('currentUserCanEdit')) {
          that.set('updateDropzoneProgressListener', dropzone);
          dropzone.save().then(function (savedDropzone) {
            savedDropzone.set('icon_type', null);
            onSuccess(savedDropzone);
          }, onFail);
        } else {
          if (dropzone.constructor.modelName == 'dropzone') {
            var query = {
              dz_id: dropzone.get('id')
            };
            this.store.queryRecord('user-dropzone', query).then(function (userDropzone) {
              //console.log("anonymousDropzone:"+anonymousDropzone);
              if (!userDropzone) {
                userDropzone = that.store.createRecord('user-dropzone', {
                  dz_id: dropzone.get('id')
                });
              }
              that.set('updateDropzoneProgressListener', userDropzone);
              (0, _copyDropzoneToDropzone.default)(dropzone, userDropzone);
              userDropzone.save().then(onSuccess, onFail);
            });
          } else {
            that.set('updateDropzoneProgressListener', dropzone);
            (0, _copyDropzoneToDropzone.default)(dropzone, dropzone);
            dropzone.save().then(onSuccess, onFail);
          }
        }
      },
      cancelEditDropzoneAction: function cancelEditDropzoneAction(dropzone) {
        //console.log('editDropzone');
        Ember.get(this, 'metrics').trackEvent({
          event: "Cancel Edit Dropzone",
          dropzone: dropzone.get('displayName')
        });
        this.doCancelEditDropzone(dropzone);
      }
    },
    //doCancelEditDropzone(dropzone) {
    doCancelEditDropzone: function doCancelEditDropzone() {
      this.set('dropzoneEditMode', false);
      var model = this.get('selectedDropzone');
      //this.set('selectedDropzone', null);
      model.rollbackAttributes();

      //aaa
      /*
      if(this.get('selectedDropzone') && this.get('selectedDropzone').get('manual')) {
        this.store.unloadRecord(this.get('selectedDropzone'));
      } else {
        let model = this.get('selectedDropzone');
        //this.set('selectedDropzone', null);
        model.rollbackAttributes();
      }
      */
    }
  });
});