define("spotassist/controllers/auth/password-reset", ["exports", "spotassist/config/environment", "spotassist/utils/global-error-handler"], function (_exports, _environment, _globalErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    cookies: Ember.inject.service('cookies'),
    actions: {
      resetPasswordAction: function resetPasswordAction() {
        console.info('resetPasswordAction');
        var data = this.getProperties('email');
        var that = this;
        this.set('resetProgress', true);
        var csrftoken = this.get('cookies').read('csrftoken');
        Ember.$.ajax({
          type: "POST",
          headers: {
            "X-CSRFToken": csrftoken
          },
          url: _environment.default.adapter_host + '/auth/users/reset_password/',
          data: data,
          dataType: 'json',
          success: function success() {
            console.info("reset success");
            that.set('resetProgress', false);
            that.transitionToRoute('auth.password-reset-done');
          },
          error: function error(_error) {
            var message = 'Unable to reset password with credentials error:' + JSON.stringify(_error.responseJSON);
            console.error(message);
            (0, _globalErrorHandler.default)(_error, message);
            that.set('resetProgress', false);
            that.set('error', _error);
          }
        });
      }
    },
    resetDisabled: Ember.computed('email', function () {
      var data = this.getProperties('email');
      return Ember.isEmpty(data.email);
    }),
    usernameErrors: Ember.computed('error', function () {
      if (this.get('error')) {
        var usernameErrors = [];
        var response = this.get('error').responseJSON;
        if (response.username) {
          usernameErrors = response.username;
        }
        return usernameErrors;
      } else {
        return [];
      }
    }),
    emailErrors: Ember.computed('error', function () {
      if (this.get('error')) {
        var emailErrors = [];
        var response = this.get('error').responseJSON;
        if (response.email) {
          emailErrors = response.email;
        }
        return emailErrors;
      } else {
        return [];
      }
    }),
    nonFieldErrors: Ember.computed('error', function () {
      if (this.get('error')) {
        var nonFieldErrors = [];
        var response = this.get('error').responseJSON;
        if (response.non_field_errors) {
          nonFieldErrors = response.non_field_errors;
        }
        return nonFieldErrors;
      } else {
        return [];
      }
    })
  });
});