define("spotassist/utils/request-weather", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = requestWeather;
  function requestWeather(dropzone, successCallback, errorCallback) {
    //var includeFields = "include_fields=windaloft,rawinsonde,metar,openweather";
    var includeFields = "include_fields=windaloft,metar,openweather";
    //var includeFields = "include_fields=windaloft,metar,taf,rawinsonde";
    var weatherUrl = _environment.default.adapter_host + "/weather/?lat=" + dropzone.get('lat') + "&lon=" + dropzone.get('lon') + "&" + includeFields + "&typelimit=3";
    //console.log("will request dz weather:"+weatherUrl);

    Ember.$.ajax(weatherUrl).then(function (weather) {
      var windaloftStations = [];
      var rawinsondeStations = [];
      var metarStations = [];
      weather.results.forEach(function (result) {
        if (result.windaloft && result.windaloft.length > 0) {
          windaloftStations.push(result);
        }
        if (result.rawinsonde && result.rawinsonde.length > 0) {
          if (result.rawinsonde && result.rawinsonde.length > 0) {
            var ditheredRawinsondes = ditherRaobLevels(result.rawinsonde[0].flightlevels);
            result.rawinsonde[0].flightlevels = ditheredRawinsondes;
          }
          rawinsondeStations.push(result);
        }
        if (result.metar && result.metar.length > 0) {
          metarStations.push(result);
        }
      });
      successCallback(windaloftStations, rawinsondeStations, metarStations);
    }, function (error) {
      var message = "Error loading url: " + weatherUrl;
      console.error(message + error);
      errorCallback(error, message);
    });
  }
  function ditherRaobLevels(levels) {
    //var reqLevels = [1,2,3,4,5,6,7,8,9,10,11,12,13];
    var reqLevels = [3, 6, 9, 12];
    var dithered = [];
    for (var i = 0; i < reqLevels.length; i++) {
      var reqLevel = reqLevels[i];
      for (var j = 0; j < levels.length; j++) {
        var level = levels[j];
        var feet = level.height_m * 3.28084;
        var fLevel = Math.round(feet / 1000);
        var fLevelMinus = Math.round((feet - 500) / 1000);
        var fLevelPlus = Math.round((feet + 500) / 1000);
        if (reqLevel === fLevel || reqLevel === fLevelMinus || reqLevel === fLevelPlus) {
          dithered[dithered.length] = level;
          break;
        }
      }
    }
    return dithered;
  }
});