define("spotassist/templates/auth/password-reset-done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MwNvygTz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-header \"],[9],[0,\"\\n        \"],[7,\"h3\"],[9],[0,\"Help is on the way\"],[10],[0,\"\\n\\n        \"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"\\n            We sent you a link to reset your password.\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"\\n            Please look for a mail from us, and follow easy directions.\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/auth/password-reset-done.hbs"
    }
  });
});