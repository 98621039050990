define("spotassist/components/dropzone-panel", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    router: Ember.inject.service('-routing'),
    rootURL: _environment.default.rootURL,
    actions: {
      selectDzTabAction: function selectDzTabAction(tabName) {
        this.get('selectDzTabAction')(tabName);
      },
      showBigPicture: function showBigPicture(dropzone) {
        this.get("router").transitionTo('dropzones.bigpicture', [dropzone.get('id')]);
      },
      unselectDropzoneAction: function unselectDropzoneAction(dropzone) {
        this.get('unselectDropzoneAction')(dropzone);
      },
      zoomToDropzoneAction: function zoomToDropzoneAction(dropzone) {
        this.get('zoomToDropzoneAction')(dropzone);
      },
      editDropzoneAction: function editDropzoneAction(dropzone) {
        //console.log('editDropzoneAction');
        this.get('editDropzoneAction')(dropzone);
      },
      setTemperatureUnitAction: function setTemperatureUnitAction(tempUnit) {
        this.get('setTemperatureUnitAction')(tempUnit);
      },
      setSpeedUnitAction: function setSpeedUnitAction(tempUnit) {
        this.get('setSpeedUnitAction')(tempUnit);
      },
      setAltitudeUnitAction: function setAltitudeUnitAction(tempUnit) {
        this.get('setAltitudeUnitAction')(tempUnit);
      },
      saveDropzoneAction: function saveDropzoneAction(dropzone) {
        //console.log('saveDropzoneAction');
        this.get('saveDropzoneAction')(dropzone);
      },
      cancelEditDropzoneAction: function cancelEditDropzoneAction(dropzone) {
        this.get('cancelEditDropzoneAction')(dropzone);
      },
      deleteDropzoneAction: function deleteDropzoneAction(dropzone) {
        //console.log('deleteDropzoneAction');
        this.get('deleteDropzoneAction')(dropzone);
      },
      geocodeDropzoneAction: function geocodeDropzoneAction(dropzone) {
        this.get('geocodeDropzoneAction')(dropzone);
      }
    }
  });
});