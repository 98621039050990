define("spotassist/utils/global-error-handler", ["exports", "raven"], function (_exports, _raven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = globalErrorHandler;
  function globalErrorHandler(error, message, data) {
    var extra = {};
    if (error) {
      extra.error = error;
    }
    if (message) {
      extra.message = message;
    }
    if (data) {
      extra.data = data;
    }
    _raven.default.setExtraContext(extra);
    _raven.default.captureMessage(message, error);
  }
});