define("spotassist/models/user-dropzone", ["exports", "ember-data", "spotassist/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  /* global L */
  var _default = _exports.default = Model.extend({
    dz_id: attr('string'),
    dz_name: attr('string'),
    dz_address: attr('string'),
    lat: attr('string'),
    lon: attr('string'),
    dz_phone: attr('string'),
    dz_email: attr('string'),
    dz_url: attr('string'),
    facebook_url: attr('string'),
    dz_elevation_m: attr(),
    dz_landing_corridor: attr(),
    dz_jumprun: attr(),
    dz_pattern_strategy: attr(),
    dz_downwind_ft: attr(),
    dz_base_ft: attr(),
    dz_final_ft: attr(),
    dz_ident: attr('string'),
    updated: attr('string'),
    active: attr(),
    dz_outs: attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /*
    get: function (keyName) {
      var thisValue = this._super(keyName);
      if(!thisValue && this.get('baseDropzone')) {
        var baseValue = this.get('baseDropzone').get(keyName);
        if (baseValue) {
          return baseValue
        }
      }
      return thisValue;
    },
    */

    dz_outs_shapes: Ember.computed('dz_outs', function () {
      var shapes = [];
      this.get('dz_outs').forEach(function (dz_out) {
        var shape = JSON.parse(dz_out.shape_geojson);
        shapes.addObject(shape);
      });
      return shapes;
    }),
    currentUserCanEdit: Ember.computed('session.userAcl', function () {
      if (!this.get("session.userAcl") || !this.get("session.userAcl").hasDropzoneEditAccess(this.get("id"))) {
        return false;
      } else {
        return true;
      }
    }),
    dzElevationFixer: Ember.observer('dz_elevation_m', function () {
      if (this.get('dz_elevation_m') === '') {
        this.set('dz_elevation_m', null);
      }
    }),
    location: Ember.computed('lat', 'lon', function () {
      return [this.get('lat'), this.get('lon')];
    }),
    displayName: Ember.computed('dz_name', function () {
      return this.get('dz_name');
    }),
    canBeSaved: Ember.computed('hasDirtyAttributes', 'dz_name', function () {
      return this.get('hasDirtyAttributes') && this.get('dz_name');
    }),
    popupOpen: Ember.computed('selected', 'editMode', function () {
      //console.log('icon change for '+this.get('dz_name')+' selected:'+this.get('selected')+" controller:"+controller);

      return this.get('selected') && this.get('editMode');
    }),
    draggable: Ember.computed('selected', 'editMode', function () {
      var draggable = this.get('selected') === true && this.get('editMode') === true;
      //var draggable = false;
      //console.log('draggable change for '+this.get('dz_name')+' draggable:'+draggable);

      return draggable;
    }),
    icon: Ember.computed('selected', function () {
      //console.log('icon change for '+this.get('dz_name')+' selected:'+this.get('selected'));

      return new L.icon({
        iconUrl: _environment.default.rootURL + 'assets/images/spotassist_web_logo25.png',
        iconAnchor: [16, 37],
        popupAnchor: [0, -37]
      });
    }),
    landingCorridorSet: Ember.computed('dz_landing_corridor', function () {
      return this.get('dz_landing_corridor') >= 0;
    }),
    jumprunSet: Ember.computed('dz_jumprun', function () {
      return this.get('dz_jumprun') >= 0;
    }),
    landingCorridorIcon: Ember.computed(function () {
      return new L.icon({
        iconUrl: _environment.default.rootURL + 'assets/images/landing_corridor_black.png',
        iconAnchor: [36, 143],
        className: 'corridor-opacity'
      });
    }),
    jumprunIcon: Ember.computed(function () {
      return new L.icon({
        iconUrl: _environment.default.rootURL + 'assets/images/jumprun_black.png',
        iconAnchor: [36, 143],
        className: 'corridor-opacity'
      });
    }),
    rotationRad: Ember.computed('rotation', function () {
      return this.get('rotation') * (Math.PI / 180);
    }),
    rotation: Ember.computed('dz_landing_corridor', function () {
      return this.get('dz_landing_corridor');
    }),
    jumprunRotationRad: Ember.computed('jumprunRotation', function () {
      return this.get('jumprunRotation') * (Math.PI / 180);
    }),
    jumprunRotation: Ember.computed('dz_jumprun', function () {
      return this.get('dz_jumprun');
    })
  });
});