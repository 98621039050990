define("spotassist/mixins/requestable-weather", ["exports", "spotassist/utils/request-weather", "spotassist/utils/request-gfs-weather", "spotassist/utils/request-wu-weather", "spotassist/objects/metar-station", "spotassist/utils/global-error-handler"], function (_exports, _requestWeather, _requestGfsWeather, _requestWuWeather, _metarStation, _globalErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    localSettings: Ember.inject.service('local-settings'),
    weatherSource: Ember.computed(function () {
      return this;
    }),
    dropzoneForWeather: Ember.computed('currentDropzone', 'selectedDropzone', function () {
      if (this.get('currentDropzone')) {
        return this.get('currentDropzone');
      } else if (this.get('selectedDropzone')) {
        return this.get('selectedDropzone');
      } else {
        return null;
      }
    }),
    setPreferredStation: function setPreferredStation(station, stationType) {
      var dropzone = this.get('dropzoneForWeather');
      console.info(" For dropzone " + dropzone + " setPreferredStation " + stationType + " " + station.station_name);
      var preferredStationsString = this.get('localSettings').get('settings.pref_stations');
      var preferredStations = {};
      if (preferredStationsString) {
        preferredStations = JSON.parse(preferredStationsString);
      }
      var dzSettings = preferredStations[dropzone.get("id")];
      if (!dzSettings) {
        dzSettings = {};
        preferredStations[dropzone.get("id")] = dzSettings;
      }
      dzSettings[stationType] = station.station_id;
      var newPreferredStationsString = JSON.stringify(preferredStations);
      this.get('localSettings').set('settings.pref_stations', newPreferredStationsString);
    },
    findPreferredStation: function findPreferredStation(dropzone, stationType) {
      var preferredStationsString = this.get('localSettings').get('settings.pref_stations');
      if (!preferredStationsString) {
        return null;
      }
      var preferredStations = JSON.parse(preferredStationsString);
      var dzSettings = preferredStations[dropzone.get("id")];
      if (!dzSettings) {
        return null;
      }
      var preferredStation = dzSettings[stationType];
      return preferredStation;
    },
    windaloftStation: Ember.computed('windaloftStations', 'localSettings.settings.pref_stations', function () {
      var windaloftStation = null;
      if (!Ember.isEmpty(this.get('windaloftStations')) && this.get('windaloftStations').length > 0) {
        var dropzone = this.get('dropzoneForWeather');
        var preferredStation = this.findPreferredStation(dropzone, "windaloft");
        if (preferredStation) {
          windaloftStation = this.get('windaloftStations').findBy('station_id', preferredStation);
        }
        if (!windaloftStation) {
          windaloftStation = this.get('windaloftStations')[0];
        }
      }
      return windaloftStation;
    }),
    rawinsondeStation: Ember.computed('rawinsondeStations', 'localSettings.settings.pref_stations', function () {
      var rawinsondeStation = null;
      if (!Ember.isEmpty(this.get('rawinsondeStations')) && this.get('rawinsondeStations').length > 0) {
        var dropzone = this.get('dropzoneForWeather');
        var preferredStation = this.findPreferredStation(dropzone, "rawinsonde");
        if (preferredStation) {
          rawinsondeStation = this.get('rawinsondeStations').findBy('station_id', preferredStation);
        }
        if (!rawinsondeStation) {
          rawinsondeStation = this.get('rawinsondeStations')[0];
        }
      }
      return rawinsondeStation;
    }),
    metarStation: Ember.computed('metarStations', 'localSettings.settings.pref_stations', function () {
      var metarStation = null;
      if (!Ember.isEmpty(this.get('metarStations')) && this.get('metarStations').length > 0) {
        var rawMetarStation = null;
        var dropzone = this.get('dropzoneForWeather');
        var preferredStation = this.findPreferredStation(dropzone, "metar");
        if (preferredStation) {
          rawMetarStation = this.get('metarStations').findBy('station_id', preferredStation);
        }
        if (!rawMetarStation) {
          rawMetarStation = this.get('metarStations')[0];
        }
        metarStation = _metarStation.default.create(rawMetarStation);
      }
      return metarStation;
    }),
    requestDropzoneWeather: function requestDropzoneWeather(dropzone) {
      //var dropzone = this.get('model');
      //dropzone.set('dz_landing_corridor', 50);
      if (!dropzone) {
        return;
      }
      console.info("requesting new weather for dropzone " + dropzone.get('dz_name'));
      var that = this;
      var weatherSuccess = function weatherSuccess(windaloftStations, rawinsondeStations, metarStations) {
        console.info("requesting new weather SUCCESS");

        //that.switchModes();
        that.set('windaloftStations', null);
        that.set('metarStations', null);
        that.set('wuObservations', null);
        that.set('weatherLoadingProgress', false);
        that.set('weatherLoadingSuccess', true);

        //if(!isEmpty(windaloftStations) && windaloftStations.length>0) {
        that.set('windaloftStations', windaloftStations);
        //}

        //if(!isEmpty(rawinsondeStations) &&  rawinsondeStations.length>0) {
        that.set('rawinsondeStations', rawinsondeStations);
        //}

        if ((Ember.isEmpty(windaloftStations) || windaloftStations.length <= 0) && (Ember.isEmpty(rawinsondeStations) || rawinsondeStations.length <= 0)) {
          (0, _globalErrorHandler.default)(null, 'No Upper wind stations found', {
            dz_id: dropzone.get('dz_name'),
            windaloftStations: windaloftStations,
            rawinsondeStations: rawinsondeStations
          });
        }
        that.set('metarStations', metarStations);
        (0, _requestGfsWeather.default)(dropzone).then(function (gfsStations) {
          gfsStations.forEach(function (gfsStation) {
            var newWindaloftStations = JSON.parse(JSON.stringify(windaloftStations));
            newWindaloftStations.unshift(gfsStation);
            that.set('windaloftStations', newWindaloftStations);
            var newMetarStations = JSON.parse(JSON.stringify(that.get('metarStations')));
            newMetarStations.unshift(gfsStation);
            that.set('metarStations', newMetarStations);
          });

          //that.set('metarStations', newMetarStations);

          if (dropzone.get('dz_wu_station_id')) {
            var wuWeaherSuccess = function wuWeaherSuccess(current, observations) {
              console.log("requesting WUweather SUCCESS");
              var metarStation = that.convertToMetarStation(current, observations);
              var newMetarStations = JSON.parse(JSON.stringify(that.get('metarStations')));
              newMetarStations.unshift(metarStation);
              that.set('metarStations', newMetarStations);
              that.set('wuObservations', observations);
            };
            var wuWeaherError = function wuWeaherError() {
              console.error("requesting WUweather ERROR");
              //that.set('metarStations', metarStations);
            };
            (0, _requestWuWeather.default)(dropzone.get('dz_wu_station_id'), dropzone.get('dz_wu_api_key'), wuWeaherSuccess, wuWeaherError);
          }
        });
        if (Ember.isEmpty(metarStations) && metarStations.length > 0) {
          (0, _globalErrorHandler.default)(null, 'No Surface wind stations found', {
            dz_id: dropzone.get('dz_name'),
            metarStations: metarStations
          });
        }
      };
      var weatherError = function weatherError(error, message) {
        console.error("requesting new weather ERROR");
        (0, _globalErrorHandler.default)(error, message);
        that.set('weatherLoadingProgress', false);
        that.set('weatherLoadingSuccess', false);
      };
      that.set('weatherLoadingProgress', true);
      (0, _requestWeather.default)(dropzone, weatherSuccess, weatherError);
    },
    convertToMetarStation: function convertToMetarStation(current, observations) {
      var currentData = current.observations[current.observations.length - 1];
      var observationData = observations.observations[observations.observations.length - 1];
      /*
        epoch: 1568865896
        humidityAvg: 98
        humidityHigh: 98
        humidityLow: 98
        imperial: {
          dewptAvg: 46
          dewptHigh: 46
          dewptLow: 46
          heatindexAvg: 47
          heatindexHigh: 47
          heatindexLow: 47
          precipRate: 0
          precipTotal: 0
          pressureMax: 30.03
          pressureMin: 30.02
          pressureTrend: 0
          tempAvg: 47
          tempHigh: 47
          tempLow: 47
          windchillAvg: 47
          windchillHigh: 47
          windchillLow: 47
          windgustAvg: 0
          windgustHigh: 0
          windgustLow: 0
          windspeedAvg: 0
          windspeedHigh: 0
          windspeedLow: 0
        }
        lat: 41.6590004
        lon: -74.14800262
        obsTimeLocal: "2019-09-19 00:04:56"
        obsTimeUtc: "2019-09-19T04:04:56Z"
        qcStatus: 1
        solarRadiationHigh: 0
        stationID: "KNYGARDI10"
        tz: "America/New_York"
        uvHigh: 0
        winddirAvg: 342
      */
      var metarStation = {
        distance: 0,
        lat: observationData.lat,
        lon: observationData.lon,
        site: currentData.neighborhood,
        station_id: observationData.stationID,
        station_name: currentData.neighborhood + ", " + currentData.country,
        station_types: [{
          station_type: "METAR"
        }],
        metar: [{
          altim_in_hg: 30.268702,
          dewpoint_c: observationData.imperial.dewptAvg,
          observation_time: observationData.obsTimeUtc,
          //var temp_userValue = conversionUtils.convertTemperature(temp_c_intValue, 'C', this.get('localSettings').get('settings.temperature_unit'));
          temp_c: (observationData.imperial.tempAvg - 32) * (5 / 9),
          wind_dir_degrees: observationData.winddirAvg,
          wind_gust_kt: observationData.imperial.windgustAvg * 0.868976,
          wind_speed_kt: observationData.imperial.windspeedAvg * 0.868976,
          sky_conditions: []
        }]
      };
      return metarStation;
    }
  });
});