define("spotassist/components/dropzone-panel-header", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    rootURL: _environment.default.rootURL,
    //addToWebsite, openFullMap
    callToAction: 'openFullMap',
    callToActionIsMap: Ember.computed('callToAction', function () {
      return this.get('callToAction') === 'openFullMap';
    }),
    callToActionIsWizard: Ember.computed('callToAction', function () {
      return this.get('callToAction') === 'addToWebsite';
    })
  });
});