define("spotassist/templates/components/stations-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bpphwEdc",
    "block": "{\"symbols\":[\"station\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"btn-group\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-default btn-xs dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"false\"],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"caret\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu\"],[11,\"aria-labelledby\",\"dropdownMenu4\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"stations\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\"],[9],[0,\"\\n                \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n                    \"],[7,\"span\"],[11,\"class\",\"label label-default\"],[9],[0,\"\\n                        \"],[1,[22,1,[\"station_id\"]],false],[0,\"\\n                    \"],[10],[0,\"\\n                    \"],[1,[22,1,[\"station_name\"]],false],[0,\" - \"],[1,[27,\"format-distance\",[[22,1,[\"distance\"]],\"mi\",[23,[\"localSettings\",\"settings\",\"distance_unit\"]]],null],false],[0,\" away\\n                \"],[3,\"action\",[[22,0,[]],\"setPreferredStation\",[22,1,[]]]],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/components/stations-select.hbs"
    }
  });
});