define("spotassist/utils/geocode-dropzone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = geocodeDropzone;
  /* global google */

  function geocodeDropzone(dropzone) {
    var geocoder = new google.maps.Geocoder();
    var query = dropzone.get('dz_address');
    geocoder.geocode({
      "address": query
    }, function (geoResults, status) {
      if (status === google.maps.GeocoderStatus.OK && geoResults.length > 0) {
        var geoResult = geoResults[0];

        //console.log("geocoded: "+geoResult.formatted_address+" "+geoResult.geometry.location.lat()+","+geoResult.geometry.location.lng());

        dropzone.set('lat', geoResult.geometry.location.lat());
        dropzone.set('lon', geoResult.geometry.location.lng());
      }
    });
  }
});