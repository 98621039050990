define("spotassist/routes/dropzones/view", ["exports", "spotassist/mixins/trackable-route", "spotassist/mixins/seo-route"], function (_exports, _trackableRoute, _seoRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_trackableRoute.default, _seoRoute.default, {
    controllerName: 'dropzones.index',
    actions: {
      openNavAction: function openNavAction() {
        this.get('user-settings').openNav();
      },
      closeNavAction: function closeNavAction() {
        this.get('user-settings').closeNav();
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        this.trackPage();
      }
    },
    beforeModel: function beforeModel() /*transition*/{
      this.get('user-settings').closeNav();
      this.get('user-settings').set('nav-bar-map-extension', false);
      this.get('user-settings').set('hide-app-menu', true);
    },
    afterModel: function afterModel(model) {
      var title = 'Spot Assist Dropzone Widget';
      var description = 'This Widget can be placed at your webpage to show weather and map of your dropzone.';
      var imageUrl = 'http://www.spotassist.com/wp-content/uploads/2017/02/dropzones-spotassist.png';
      this.setHeadTags(model, title, description, imageUrl);
    },
    model: function model(params) {
      return this.store.findRecord('dropzone', params.dropzone_id);
    },
    setupController: function setupController(controller, model) {
      controller.set('selectedDropzone', model);
    }
  });
});