define("spotassist/templates/components/dropzone-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "j1X8rNWs",
    "block": "{\"symbols\":[\"context\"],\"statements\":[[7,\"div\"],[11,\"id\",\"dz-map-container\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"dropzone\"]]],null,{\"statements\":[[4,\"leaflet-map\",null,[[\"lat\",\"lng\",\"zoomControl\",\"zoom\",\"maxZoom\"],[[23,[\"dropzone\",\"lat\"]],[23,[\"dropzone\",\"lon\"]],true,15,25]],{\"statements\":[[0,\"\\n\"],[0,\"      \"],[1,[27,\"google-mutant-layer\",null,[[\"type\",\"isTrafficOn\"],[\"satellite\",true]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"dropzone\",\"landingCorridorSet\"]]],null,{\"statements\":[[0,\"                \"],[1,[27,\"marker-layer\",null,[[\"location\",\"icon\",\"rotationAngle\",\"rotationOrigin\"],[[23,[\"dropzone\",\"location\"]],[23,[\"dropzone\",\"landingCorridorIcon\"]],[23,[\"dropzone\",\"rotation\"]],\"50% 50%\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\"],[1,[27,\"marker-layer\",null,[[\"location\",\"title\",\"alt\",\"draggable\",\"icon\",\"onDragend\"],[[23,[\"dropzone\",\"location\"]],[23,[\"dropzone\",\"dz_name\"]],[23,[\"dropzone\",\"id\"]],[23,[\"dropzone\",\"draggable\"]],[23,[\"dropzone\",\"icon\"]],\"markerLocationChanged\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/components/dropzone-map.hbs"
    }
  });
});